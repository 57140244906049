import { DisasterMessageCode } from "../constants/disasterMessages";
import url from "./utilities";


export const localStorageProvider = (() => {
  const DEVICE_ID_MIC = "SETTINGS_MIC_DEVICE_ID",
    DEVICE_ID_SPEAKER = "SETTINGS_SPEAKER_DEVICE_ID",
    DEVICE_ID_CAM = "SETTINGS_CAM_DEVICE_ID",
    CAM_DISABLED = "SETTINGS_CAM_DISABLED",
    MIC_DISABLED = "SETTINGS_MIC_DISABLED",
    INVITE_MEMBER_TOKEN = "INVITE_MEMBER_TOKEN",
    BEFORE_MAGIC_LINK_URL = "BEFORE_MAGIC_LINK_URL",
    DISASTER_MESSAGE_SHOWN_CODES = "DISASTER_MESSAGE_SHOWN_CODES",
    USER_TOKEN = "USER_TOKEN",
    LAST_IP_LOCATION_INFO = "LAST_IP_LOCATION_INFO"

  const noAccess = (() => {
    try {
      window.localStorage.getItem("test")
      return false
    } catch (error) {
      return true
    }
  })()

  const setItem = (key: string, value: string | boolean | object) => {
    if (noAccess)
      return
    let resultValue = ''

    switch (typeof value) {
      case "string":
        resultValue = value
        break;
      case "object":
        resultValue = JSON.stringify(url.trimObject(value))
        break;
      case "boolean":
        resultValue = value.toString()
        break;
    }

    window.localStorage.setItem(key, resultValue)
  }

  const getItem = (key: string) => {
    if (noAccess)
      return null

    return window.localStorage.getItem(key)
  }

  const removeItem = (key: string) => {
    if (noAccess)
      return null

    window.localStorage.removeItem(key)
  }

  const _getDisasterMessageShownCodes = () => {
    const codesStr = getItem(DISASTER_MESSAGE_SHOWN_CODES)

    if (!codesStr)
      return []

    return codesStr.split(',') as DisasterMessageCode[]
  }

  return {
    speakerDeviceId: {
      get: () => getItem(DEVICE_ID_SPEAKER) ?? undefined,
      set: (newDeviceId: string) => setItem(DEVICE_ID_SPEAKER, newDeviceId),
      remove: () => removeItem(DEVICE_ID_SPEAKER),
    },
    micDeviceId: {
      get: () => getItem(DEVICE_ID_MIC) ?? undefined,
      set: (newDeviceId: string) => setItem(DEVICE_ID_MIC, newDeviceId),
      remove: () => removeItem(DEVICE_ID_MIC)
    },
    cameraDeviceId: {
      get: () => getItem(DEVICE_ID_CAM) ?? undefined,
      set: (newDeviceId: string) => setItem(DEVICE_ID_CAM, newDeviceId),
      remove: () => removeItem(DEVICE_ID_CAM),
    },
    cameraDisabled: {
      get: () => getItem(CAM_DISABLED) === "true",
      set: (disabled: boolean) => setItem(CAM_DISABLED, disabled),
    },
    micDisabled: {
      get: () => getItem(MIC_DISABLED) === "true",
      set: (disabled: boolean) => setItem(MIC_DISABLED, disabled),
    },
    userToken: {
      get: () => getItem(USER_TOKEN),
      set: (token: string) => setItem(USER_TOKEN, token),
      remove: () => removeItem(USER_TOKEN)
    },
    inviteMemberToken: {
      get: () => getItem(INVITE_MEMBER_TOKEN),
      set: (token: string) => setItem(INVITE_MEMBER_TOKEN, token),
      remove: () => removeItem(INVITE_MEMBER_TOKEN)
    },
    beforeMagicLinkUrl: {
      get: () => getItem(BEFORE_MAGIC_LINK_URL),
      set: (url: string) => setItem(BEFORE_MAGIC_LINK_URL, url),
      remove: () => removeItem(BEFORE_MAGIC_LINK_URL)
    },
    ///Comma separated codes
    disasterMessageShownCodes: {
      get: _getDisasterMessageShownCodes,
      set: (code: DisasterMessageCode) => {
        let stored = _getDisasterMessageShownCodes()
        stored.push(code)
        setItem(DISASTER_MESSAGE_SHOWN_CODES, stored.join(','))
      },
      remove: () => removeItem(DISASTER_MESSAGE_SHOWN_CODES)
    },
    lastIPInfo: {
      get: () => {
        const stored = getItem(LAST_IP_LOCATION_INFO)
        if (stored === null)
          return stored

        return JSON.parse(stored) as IPLocationInfo
      },
      set: (ipLocationInfo: IPLocationInfo) => setItem(LAST_IP_LOCATION_INFO, ipLocationInfo),
      remove: () => removeItem(LAST_IP_LOCATION_INFO)
    },
  }
})()