import { apiUrl, apiTenant, apiApplicationKey, apiApplicationSecret, uiLogAPIToken, IP_LOCATION_API_KEY } from '../constants/environment'
import utilities from './utilities'
import Bowser from 'bowser';

let _API_TOKEN = ''
let _ROOM_ID = ''
let _SPACE_ID = 0
let _PARTICIPANT_ID: number | null = null

const _BROWSER_INFO = JSON.stringify(Bowser.parse(navigator.userAgent))

interface AjaxCallOptions {
  headerRoomId?: number | string
  headerSpaceId?: number
  headerParticipantId?: number
  refreshToken?: boolean
  noParticipantId?: boolean
  customToken?: string//for debugging
}

const call = async (method: "GET" | "POST" | "DELETE" | "PUT", url: string, body?: object | FormData, options?: AjaxCallOptions) => {
  let requestBody: undefined | string | FormData = undefined
  const { headerRoomId, headerSpaceId, refreshToken, noParticipantId, headerParticipantId = _PARTICIPANT_ID } = options || {}

  if (body) {
    if (body instanceof FormData || typeof (body) === "string")
      requestBody = body
    else {//trim all string properties in the body object
      requestBody = JSON.stringify(utilities.trimObject(body))
    }
  }

  let headers: any = {
    "Pragma": "no-cache",
    "tenant": apiTenant,
    "applicationKey": apiApplicationKey,
    "applicationSecret": apiApplicationSecret,
    "room-id": headerRoomId || '',//_ROOM_ID,
    "space-id": headerSpaceId || '',
    // "Authorization": `Bearer ${_API_TOKEN}`
  }

  if (headerParticipantId && !noParticipantId)
    headers["participant-id"] = headerParticipantId

  if (refreshToken)
    headers["refresh-token"] = true

  if (url.toLocaleLowerCase().indexOf("ui-log-events") >= 0)
    headers["ui-log-auth-token"] = uiLogAPIToken
  else if (options?.customToken)
    headers["Authorization"] = `Bearer ${options?.customToken}`
  else
    headers["Authorization"] = `Bearer ${_API_TOKEN}`

  if (body && !(body instanceof FormData || typeof (body) === "string"))
    headers["Content-Type"] = "application/json"

  if (!url.toLowerCase().startsWith("v3") && !url.toLowerCase().startsWith("v4"))
    url = `v2/${url}`

  try {
    const response = await fetch(`${apiUrl}/api/${url}`, {
      method,
      keepalive: true,
      body: requestBody,
      headers,
    })

    if (!response.ok)
      console.log("API not OK", response)

    return response
  }
  catch (err) {
    //set app context error state
    console.log("API Error", err)

    if (url.toLowerCase().indexOf('public/ui-log-events') < 0)
      api.log.add("Fetch error", { method, url, body })

    return null
  }
}

const api = {
  resetAPIToken: () => _API_TOKEN = '',
  setCurrentRoomId: (id: number | null) => _ROOM_ID = id ? id.toString() : '',
  setSpaceId: (spaceId: number) => _SPACE_ID = spaceId,
  setCurrentParticipantId: (participantId: number | null) => _PARTICIPANT_ID = participantId,

  daily: {
    getRoomUrl: async (spaceId: number, roomId: number, userName: string, startCameraOff: boolean | null, startAudioOff: boolean | null) => {
      const response = await call("POST", `daily/room`, {
        spaceId,
        roomId,
        privacy: "private",
        sfuSwitchOver: true,
        userName,
        startCameraOff,
        startAudioOff
      })

      if (!response || !response.ok)
        return null

      const result: { roomUrl: string, roomId: number, meetingToken: string | null } = await response.json()

      if (result.roomId !== roomId)
        return null

      return result
    },
    getRecordings: async (roomName: string, limit?: number, afterId?: string) => {
      let url = `daily/recordings?room_name=${roomName}&limit=${limit || 10}`;
      if (afterId) {
        url = `${url}&starting_after=${afterId}`;
      }
      const response = await call("GET", url);
      if (!response || !response.ok)
        return null

      const result = await response.json() as DailyRecordingsResult;
      return result;
    },
    getRecordingsByDate_: async (roomName: string, startDate: Date, endDate: string) => {
      let url = `daily/recordings?room_name=${roomName}&starting_after=${startDate}&ending_before=${endDate}`;

      const response = await call("GET", url);

      if (!response || !response.ok)
        return null

      const result = await response.json();

      return result.data as DailyRecording;
    },
    getRecording: async (recordingId: string) => {
      const response = await call("GET", `daily/recordings/${recordingId}`)
      if (!response || !response.ok)
        return null

      const result = await response.json();
      return result;
    },
    getRecordingAccessLink: async (recordingId: string) => {
      const response = await call("GET", `daily/recordings/${recordingId}/access-link`)
      if (!response || !response.ok)
        return null

      const result = await response.json();
      return result;
    },
    deleteRecording: async (recordingId: string) => {
      return await call("DELETE", `daily/recordings/${recordingId}`);
    },
  },
  space: {
    get: async (spaceUrl: string) => {
      const response = await call("GET", `public/space/get?url-suffix=${spaceUrl}`)

      if (!response || !response.ok)
        return null

      const space = await response.json() as Space;

      return space;
    },
    updateSpaceInfo: async (id: number, name: string, urlSuffix: string, timezone: string) => {
      const response = await call('PUT', 'space/basic', {
        id,
        name,
        urlSuffix,
        timezone,
        startDate: "",
        time: ""
      })

      if (response?.ok)
        return await response.json() as Space

      return null
    },
    updateSpaceLogo: async (spaceId: number, spaceFileId: number) => {
      const response = await call('PUT', 'space/logo', {
        spaceId,
        spaceFileId
      })

      if (response?.ok)
        return await response.json() as Space

      return null
    },
    updateSpaceBackground: async (spaceId: number, spaceFileId: number) => {
      const response = await call('PUT', 'space/background', {
        spaceId,
        spaceFileId
      })

      if (response?.ok)
        return await response.json() as Space

      return null
    },
    updateSpaceLock: async (spaceId: number, accessLevelType: AccessLevelType) => {
      const response = await call('PUT', 'space/lock', {
        spaceId,
        accessLevelType
      })

      if (response?.ok)
        return await response.json() as Space

      return null
    },
    updateSpaceAccess: async (spaceId: number, spaceAccess: SpaceRoomAccess) => {
      const response = await call('PUT', 'space/access', { ...spaceAccess, spaceId })

      if (response?.ok)
        return await response.json() as Space

      return null
    },
    contactUs: async (spaceId: number, name: string, email: string, description: string, roomId: null | number) => {
      const response = await call('POST', 'public/contact-us-form', {
        spaceId,
        name,
        email,
        description,
        roomId
      })

      if (response?.ok)
        return await response.json();

      return null
    },
    getMemberInviteLink: async (spaceId?: number, participantId?: number) => {
      const response = await call('POST', 'v4/user/space/invite-member', {}, {
        headerSpaceId: spaceId,
        headerParticipantId: participantId
      })

      if (response?.ok)
        return (await response.json()).link as string

      return null
    },
    inviteUsers: async (emails: string[], spaceId: number, isGuest: boolean, spaceAdmin: boolean, inviteLink: string, myParticipantId?: number) => {
      let body: any = {
        emails,
        spaceId,
        spaceAdmin,
        url: isGuest ? inviteLink : null
      }

      const apiUrl = `space/invite-users${!isGuest ? '-as-member-via-email' : ''}`

      const response = await call('POST', apiUrl, body, {
        headerParticipantId: myParticipantId
      })

      if (response?.ok)
        return true;

      return null;
    },
  },
  floor: {
    getAll: async (spaceId: number) => {
      const response = await call("GET", `space/floor/all?space_id=${spaceId}`)

      if (response?.ok) {
        const fs = await response.json() as Floor[]
        return fs.map(f => ({
          ...f,
          settings: f.settings ? JSON.parse(f.settings as any) : {}
        } as Floor))
      }
    },
    create: async (floorName: string, sequenceNo: number) => {
      const response = await call("POST", `space/floor`, {
        floorName,
        sequenceNo,
      })
      if (response?.ok) {
        const floor = await response.json() as Floor
        return floor;
      }
    },
    delete: async (floorId: number) => {
      await call("DELETE", `space/floor`, {
        floorId,
      })
    },
    update: async (floorId: number, floorName: string) => {
      const response = await call("PUT", `space/floor`, {
        floorName,
        floorId
      })

      if (response?.ok) {
        return true
      }
      return false
    },
    getComponents: async (floorId: number) => {
      const response = await call("GET", `space/floor/component/all?floor_id=${floorId}`)

      if (response?.ok) {
        const fcs = await response.json() as FloorComponent[]
        return fcs.map(fc => ({
          ...fc,
          settings: JSON.parse(fc.settings as any) as FloorComponentSettings
        })) as FloorComponent[]
      }
      return []
    },
    createComponent: async ({
      floorId,
      componentType,
      settings,
      roomName,
      roomDescription,
    }: FloorComponentRequest) => {
      const response = await call("POST", `space/floor/component`, {
        floorId,
        componentType,
        roomName,
        roomDescription,
        settings: JSON.stringify(settings),
      });
      if (response?.ok)
        return await response.json() as FloorComponent

      return null
    },
    updateFloorComponentSetting: async (floorComponentId: number, settings: FloorComponentSettings) => {
      const response = await call("PUT", `space/floor/component/settings`, {
        floorComponentId,
        settings: JSON.stringify(settings)
      })

      if (response?.ok) {
        return true
      }
      return false
    },
    deleteFloorComponent: async (floorComponentId: number) => {
      await call("DELETE", `space/floor/component`, {
        floorComponentId,
      })
    },
  },
  room: {
    getAll: async (spaceId: number) => {
      const response = await call("GET", `room/all?space_id=${spaceId}&need_room_access=true&need_participants=false&need_participant_room_roles=true`)

      if (response?.ok)
        return await response.json() as Room[]

      return null
    },
    getRoomDetails: async (roomId: number) => {
      const response = await call("GET", `room?room_id=${roomId}`)

      if (response?.ok) {
        const room = await response.json() as Room
        const result: Room = {
          ...room,
          participantRoomRoleList: (room.participantRoomRoleList || []).map(pr => ({
            active: pr.active,
            participantId: pr.participantId,
            roleId: pr.roleId,
          }))
        }

        return result
      }

      return null
    },
    join: async (roomId: number, participantId: number,
      roomPassword: string, startCameraOff: boolean | null,
      startAudioOff: boolean | null, settings: ParticipantSettings,
      enableRecording: boolean, maxInCallTimeSecs: number) => {
      const body = {
        roomId,
        participantId,
        roomPassword,
        dailyRoomURLRequest: {
          privacy: "private",
          sfuSwitchOver: true,
          startCameraOff,
          startAudioOff,
          dailyGeo: null,

          // userNameAdditionalProperties: settings,

          settings,
          setOnlyNameInToken: true,

          expireTokenInHours: 8,
          properties: {
            enable_recording: enableRecording ? "cloud" : null,
            eject_at_token_exp: true,
            eject_after_elapsed: maxInCallTimeSecs,
            //does't work!
            // redirect_on_meeting_exit: 'about:blank'
          },
          requestedRoomProperties: {
            enable_pip_ui: true,
            enable_emoji_reactions: true,
            enable_hand_raising: true,
            enable_prejoin_ui: true,
            enable_people_ui: false,
            enable_noise_cancellation_ui: true,
            enable_video_processing_ui: true,
            enable_recording: "cloud"
          }
        }
      }

      const response = await call('POST', 'v3/room/join-room', body)

      if (!response)
        return {
          error: "Couldn't join the room.",
        }

      if (response.status === 401)
        return {
          error: "Invalid room password!"
        }

      const result = await response.json()
      const { activityType: status } = result

      if (status === "JOINED_ROOM") {
        const { dailyRoomUrl, dailyMeetingToken } = result.dailyRoomCredentials

        return {
          error: null,
          waitingRoom: false,
          dailyRoomUrl,
          dailyMeetingToken
        }
      }

      if (status === "WAITING_ROOM") {
        return {
          error: null,
          waitingRoom: true
        }
      }

      return {
        error: `Invalid result type (${status})!`
      }
    },
    validatePassword: async (roomId: number, roomPassword: string) => {
      const response = await call('POST', 'room/validate-room-password', { roomId, roomPassword })

      if (response?.ok)
        return await response.json() as { valid: boolean }

      return null
    },
    updateVisibility: async (roomId: number, roomVisibility: boolean) => {
      await call('PUT', 'room/visibility', { roomId, roomVisibility }, { headerRoomId: roomId })
    },
    create: async (spaceId: number, roomName: string, sequence: number, isVisible: boolean, roomStructureType: string) => {
      const response = await call('POST', 'room', {
        spaceId,
        roomName,
        sequence,
        isVisible,
        roomStructureType
      })

      if (response?.ok)
        return await response.json() as Room

      return null
    },
    updateRoomsOrder: async (rooms: Room[]) => {
      await call('PUT', 'room/reordering', rooms.map(r => ({ roomId: r.roomId, roomSequenceNumber: r.sequenceNo })))
    },
    updateRoomBackground: async (roomId: number, spaceFileId: number) => {
      await call('PUT', 'room/background', { roomId, spaceFileId }, { headerRoomId: roomId })
    },
    updateRoomLock: async (spaceId: number, roomId: number, accessLevelType: AccessLevelType | null) => {
      await call('PUT', 'room/lock', { spaceId, roomId, accessLevelType: accessLevelType || '' }, { headerRoomId: roomId })
    },
    updateRoomLockWithCustomMessage: async (roomId: number, accessLevelType: AccessLevelType, customMessage: string) => {
      await call('PUT', 'v3/space-room-access/update-lock-and-message', { roomId, accessLevelType, customMessage }, { headerRoomId: roomId })
    },
    updateRoom: async (data: RoomRequest) => {
      const response = await call('PUT', 'room', data, { headerRoomId: data.roomId });

      return !!response?.ok
    },
    updateParticipantRoomRole: async (data: {
      roomId: number,
      participantId: number,
      roleId: number,
      active: boolean,
    }) => {
      await call('PUT', 'participants-room-role', data, { headerRoomId: data.roomId });
    },
    updateRoomStructureSetting: async (roomId: number, roomStructureSetting: RoomStructureSetting) => {
      const response = await call('PUT', 'room/structure_setting', {
        roomStructureSetting: JSON.stringify(roomStructureSetting),
        roomId
      }, { headerRoomId: roomId });
      return !!response?.ok;
    },
    deleteRoom: async (roomId: number) => {
      return await call('DELETE', `room?room_id=${roomId}`)
    },
    updateParticipantWaitingStatus: async (roomId: number, participantId: number, accepted: boolean) => {
      await call('PUT', 'v3/room/update-participant-waiting-room-status', { roomId, participantId, accepted }, { headerRoomId: roomId })
    },
    sendWaitingRoomEmail: async (roomId: number, participantId: number) => {
      await call('PUT', 'v3/room/send-waiting-room-email', { roomId, participantId })
    },
    setAsSpaceDefaultRoom: async (roomId: number) => {
      await call('PUT', 'v3/room/set-default-room', { roomId })
    },
    getWaitingRoomLists: async (roomIds: number[]) => {
      const response = await call('GET', `v3/room/get-participant-waiting-room-list?room-ids=${roomIds.join(',')}`, undefined, { headerRoomId: roomIds[0] })

      if (response?.ok)
        return await response.json() as {
          [roomId: number]: { participant: Participant, requestedTimeEpochSecond: number }[]
        }

      return []
    },
    leaveWaitingRoom: async (roomId: number, participantId: number) => {
      await call('DELETE', 'v3/room/leave-waiting-room', {
        roomId, participantId
      })
    },
    getActiveParticipantCount: async (dailyRoomNames: string[]) => {
      const response = await call('POST', `v3/room/get-active-participant-count`, { dailyRoomIds: dailyRoomNames })

      if (response?.ok)
        return await response.json() as {
          [dailyRoomName: number]: number
        }

      return []
    },
    updateRoomCalendarLink: async (roomId: number, myCalendarLink: string) => {
      await call('PUT', 'v3/space-room-access/update-calendar-link', { roomId, myCalendarLink }, { headerRoomId: roomId })
    },
  },
  user: {
    getIP: async () => {
      const response = await fetch(`https://api.ipify.org`)

      if (response.ok)
        return await response.text()

      return null
    },
    getIPLocationInfo: async () => {
      const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${IP_LOCATION_API_KEY}`)

      if (response.ok)
        return await response.json() as IPLocationInfo

      return null
    },
    authMe: async (token: string, spaceId?: number) => {
      _API_TOKEN = token;
      const response = await call("GET", `v4/auth/me?need_master_roles=true`, undefined, {
        refreshToken: true,
        headerSpaceId: spaceId
      });
      if (response?.ok) {
        const result = await response.json() as AuthMeResult

        _API_TOKEN = result.jwtAuthenticationResponse.accessToken

        return result
      }

      console.log("response?.status ", response?.status)

      if (response?.status === 401) {
        const result = await response.json() as { message: string }

        if (result.message === "Expired token")
          return "EXPIRED"
      }

      return null
    },
    sendMagicLink: async (email: string, spaceId?: number) => {
      const response = await call("POST", `v4/public/user/send-magic-link`, {
        spaceId: spaceId || null,
        email
      })

      return !!response?.ok
    },
    login: async (email: string, password: string, spaceId: number | undefined) => {
      const response = await call("POST", `v4/public/user/login`, {
        email,
        password,
        spaceId: spaceId || null
      })

      if (!response || !response.ok) {

        if (response?.status === 401) {
          const result = await response.json() as { message: string }

          if (result.message === "You are disabled to use the space! Please contact Administrator to get it enabled.")
            return { loggedIn: false, reason: "DISABLED" }

          if (result.message === "You are not authorized to join space!")
            return { loggedIn: false, reason: "SPACE_UNAUTHORIZED" }

          if (result.message === "Space is locked!")
            return { loggedIn: false, reason: "SPACE_LOCKED" }

          if (result.message === "Room is locked!")
            return { loggedIn: false, reason: "ROOM_LOCKED" }

          return { loggedIn: false, reason: "INVALID_CREDENTIALS" }
        }

        return { loggedIn: false, reason: "ERROR" }
      }

      const result: AuthMeResult = await response.json()
      _API_TOKEN = result.jwtAuthenticationResponse.accessToken

      return { loggedIn: true, reason: '', data: result }
    },
    joinSpace: async (spaceId: number, inviteMemberToken?: string) => {
      const response = await call("PUT", `v4/user/join-space`, { spaceId, inviteMemberToken })
      if (response?.ok) {
        const result = await response.json() as CurrentParticipant

        return result
      }

      return null
    },
    checkInAudit: async (spaceId: number, socketId: string, type: "IN" | "OUT", clientInfo: Bowser.Parser.ParsedResult) => {
      const response = await call("POST", `v4/user/checkin-audit`, {
        spaceId,
        reportedTime: new Date().getTime().toString(),
        type,
        sessionId: socketId,

        additionalDetails: {
          reason: "Manual",
          socketId,
          clientInfo
        }
      })

      return response?.ok
    },
    emailExists: async (email: string) => {
      const response = await call("POST", `v4/public/user/email-exists`, {
        email
      })

      if (response?.ok) {
        const result = await response.json() as { exists: boolean }

        return result.exists!!
      }

      return null
    },
    signUp: async (spaceId: number | null, email: string, fullName: string, description?: string, inviteMemberToken?: string): Promise<AuthMeResult | User | null> => {
      const response = await call("POST", `v4/public/user/sign-up-2`, {
        email,
        firstName: fullName,
        spaceId,
        description,
        inviteMemberToken
      })

      if (response?.ok) {
        const result = await response.json()

        if (result.jwtAuthenticationResponse?.accessToken) {
          _API_TOKEN = result.jwtAuthenticationResponse.accessToken
          return result as AuthMeResult
        }

        return result as User
      }

      return null
    },
    validateTokenForActivation: async (token: string) => {
      _API_TOKEN = token
      const response = await call("GET", `v4/user/sign-up/activation`)

      if (response?.ok) {
        const result = await response.json() as AuthMeResult

        return result
      }

      return null
    },
    validateInviteMemberToken: async (token: string) => {
      const response = await call("GET", `v4/public/validate-invite-member-token?invite-member-token=${token}`)

      if (response?.ok)
        return await response.json() as ValidateInviteMemberResult

      return null
    },
    activate: async (spaceId: number, password: string) => {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("spaceId", spaceId.toString());

      const response = await call("PUT", `v4/user/sign-up/activation`, formData)

      return !!response?.ok;
    },
    resetPassword: async (password: string, token: string | null) => {
      if (token)
        _API_TOKEN = token;

      const response = await call("PUT", `v4/user/reset-password`, { password })

      if (response?.status === 401)
        return "invalid-token"

      return !!response?.ok
    },
    forgotPassword: async (email: string, spaceUrlSuffix: string | null) => {
      const response = await call("POST", `v4/public/forgot-password`, { email, spaceUrlSuffix })

      return !!response?.ok
    },
    changePassword: async (oldPassword: string, newPassword: string) => {
      const response = await call("PUT", `v4/user/change-password`, { oldPassword, newPassword }, { noParticipantId: true })

      if (!response)
        return "ERROR"

      if (!response?.ok) {
        if (response.status === 401)
          return "INVALID_PASSWORD"

        return "ERROR"
      }

      return "OK"
    },
    updateProfile: async (name: string, description: string, linkedInURL: string) => {
      const response = await call('PUT', 'v4/user/profile', {
        firstName: name,
        description,
        linkedInURL,
      }, { noParticipantId: true })

      if (!response || !response.ok)
        return "ERROR"

      return "OK"
    },
    uploadProfilePicture: async (file: File | undefined, s3Url?: string) => {
      const formData = new FormData();
      if (file)
        formData.append("file", file);
      if (s3Url)
        formData.append("s3Url", s3Url);

      const response = await call("PUT", `v4/user/profile-picture`, formData)

      if (response?.ok)
        return await response.json() as User

      return null;
    },
    createSpace: async (data: FormData) => {
      const response = await call('POST', 'v4/user/space', data)

      if (response?.status === 409)
        return "email-conflict"

      if (!response || !response.ok)
        return "error"

      const result = await response.json() as { space: Space, participant: Participant, message: string }

      return result
    },
    getProfileById: async (userId: number) => {
      const response = await call("GET", `v4/user/profile/${userId}`)

      if (response?.ok)
        return await response.json() as User

      return null
    },
  },
  participant: {
    getParticipantById: async (id: number) => {
      const response = await call("GET", `participants/${id}`)
      if (response?.ok)
        return await response.json() as Participant

      return null
    },
    add: async (spaceId: number, email: string, name: string, participantType: ParticipantType) => {
      const response = await call("POST", 'participants', {
        spaceId: spaceId,
        email: email,
        name: name,
        participantType: participantType,
      });

      if (!response)
        return null

      if (response.ok)
        return await response.json() as Participant

      if (response.status === 409)
        return "INVALID_EMAIL"

      return null
    },
    update: async (id: number, spaceId: number, email: string, name: string, participantType: ParticipantType, disabled: boolean) => {
      const response = await call("PUT", 'participants', {
        id,
        spaceId,
        email,
        name,
        participantType,
        disabled
      });

      if (!response)
        return null

      if (response.ok)
        return await response.json() as Participant

      if (response.status === 409)
        return "INVALID_EMAIL"

      return null
    },
    getAll: async (spaceId: number) => {
      const response = await call("GET", `space/participants?space_id=${spaceId}`, undefined, { headerRoomId: _ROOM_ID })

      if (response?.ok) {
        return await response.json() as Participant[];
      }

      return null
    },
    delete: async (participantId: number) => {
      return await call("DELETE", `participants?participant_id=${participantId}`)
    },
    setDefaultRoom: async (defaultRoomId: number) => {
      const response = await call("PUT", "participants/default-room", { defaultRoomId })
      return !!response?.ok
    }
  },
  chat: {
    broadcast: async (message: string) => {
      await call("POST", "chat/broadcast", { message })
    },
    get: async (roomId: number, dailySessionId: string) => {
      const response = await call("GET", `chat/room?room_id=${roomId}&daily_room_session_id=${dailySessionId}`)

      if (response?.ok)
        return await response?.json() as RoomChatMessage[]

      return []
    },
    send: async (roomId: number, senderParticipantId: number, dailyRoomSessionId: string, message: string, question?: boolean) => {
      await call("POST", "chat/room", {
        roomId,
        dailyRoomSessionId,
        senderParticipant: { id: senderParticipantId },
        message,
        question: question === true
      })
    },
    delete: async (messageId: number) => {
      await call("DELETE", `chat/room?room_chat_id=${messageId}`, undefined, { headerRoomId: _ROOM_ID })
    },
    getAllPersonal: async (page: number, pageSize: number) => {
      const response = await call("POST", `v4/chat/personal/search`, {
        pageIndex: page,
        pageSize: pageSize,
      })

      if (response?.ok)
        return await response?.json() as PersonalChatMessage[]

      return []
    },
    createPersonal: async (userId: number, message: string) => {
      await call("POST", `v4/chat/personal`, {
        receiverUser: {
          userId: userId
        },
        message: message,
      })
    },
    sendCometChatMessage: async (userId: number, message: string) => {
      await call("POST", `comet-chat/send-message`, {
        receiverUid: userId,
        payload: message,
      })
    },
    deletePersonal: async (chatId: number) => {
      await call("DELETE", `v4/chat/personal?personal_chat_id=${chatId}`)
    },
    getAllByUserId: async (userId: number, page?: number, pageSize?: number) => {
      const response = await call("POST", 'v4/chat/personal/search', {
        "pageIndex": page || 0,
        "pageSize": pageSize || 20,
        "oppositeUserId": userId,
      })
      if (response?.ok)
        return await response?.json() as PersonalChatMessage[]

      return []
    },
    markAsReadChat: async (userId: number, chatId: number) => {
      await call("PUT", `v4/chat/personal/read`, {
        senderUserId: userId,
        personalChatId: chatId,
      });
    },
  },
  resources: {
    get: async (roomId: number) => {
      const response = await call("GET", `room/resources/all?room_id=${roomId}`)

      if (response?.ok) {
        let result = await response.json() as Resource[]
        return result.map(res => ({
          ...res,
          settings: JSON.parse(res.settings as any)
        }))
      }

      return []
    },
    create: async (resource: Resource) => {
      await call("POST", 'room/resources', { ...resource, settings: JSON.stringify(resource.settings) }, { headerRoomId: resource.roomId })
    },
    update: async (resource: Resource) => {
      await call("PUT", 'room/resources', { ...resource, settings: JSON.stringify(resource.settings) }, { headerRoomId: resource.roomId })
    },
    toggleHide: async (roomResourceId: number, visible: boolean) => {
      await call("PUT", 'room/resources/hide', {
        roomResourceId,
        visible,
      }, { headerRoomId: _ROOM_ID })
    },
    delete: async (resourceId: number) => {
      await call("DELETE", `room/resources?room_resource_id=${resourceId}`, undefined, { headerRoomId: _ROOM_ID })
    },
  },
  pins: {
    getAll: async (roomId: number) => {
      const response = await call("GET", `room/pin?room_id=${roomId}`)

      if (!response || !response.ok)
        return []

      let pins: PinData[] = await response.json()

      return pins.map(p => ({ ...p, settings: JSON.parse(p.settings as any) })) as PinData[]
    },
    create: async (pinData: PinData) => {
      return await call("POST", 'room/pin', {
        ...pinData,
        settings: JSON.stringify(pinData.settings)
      }, { headerRoomId: pinData.roomId })
    },
    update: async (pinData: PinData) => {
      return await call("PUT", 'room/pin', {
        ...pinData,
        settings: JSON.stringify(pinData.settings)
      }, { headerRoomId: pinData.roomId })
    },
    delete: async (pinId: number) => {
      return await call("DELETE", `room/pin?room_pin_id=${pinId}`, undefined, { headerRoomId: _ROOM_ID })
    },
  },
  file: {
    upload: async (file: File, fileName: string, inAlbum?: boolean, compressionRequired?: boolean) => {
      const formData = new FormData();
      formData.append("fileName", fileName);
      formData.append("inAlbum", Boolean(inAlbum).toString());
      formData.append("file", file);
      formData.append("compressionRequired", (!!compressionRequired).toString());

      const response = await call("POST", `files`, formData, { headerRoomId: _ROOM_ID })

      if (response?.ok) {
        return await response.json() as SpaceFile
      }
      return null;
    },
    uploadWithProgress: async (file: File, fileName: string, roomId: number, inAlbum?: boolean, compressionRequired?: boolean, loadingCallback?: any, uploadCallback?: any) => {
      const formData = new FormData();
      formData.append("fileName", fileName);
      formData.append("inAlbum", Boolean(inAlbum).toString());
      formData.append("file", file);
      formData.append("compressionRequired", (!!compressionRequired).toString());

      const ajax = new XMLHttpRequest();
      ajax.upload.addEventListener("progress", (event) => {
        const percent = (event.loaded / event.total) * 100;
        loadingCallback && loadingCallback(percent);
      }, false);

      ajax.addEventListener("load", (event) => {
        const percent = (event.loaded / event.total) * 100;
        loadingCallback && loadingCallback(percent);
        const response = JSON.parse(ajax.response);
        uploadCallback(response);
      }, false);
      ajax.addEventListener("error", (event) => console.log('error', event), false);
      ajax.addEventListener("abort", (event) => console.log('abort', event), false);

      ajax.open("POST", `${apiUrl}/api/v2/files`);
      ajax.setRequestHeader("Pragma", "no-cache");
      ajax.setRequestHeader("tenant", apiTenant);
      ajax.setRequestHeader("applicationKey", apiApplicationKey);
      ajax.setRequestHeader("applicationSecret", apiApplicationSecret);
      if (_PARTICIPANT_ID)
        ajax.setRequestHeader("participant-id", _PARTICIPANT_ID.toString());
      ajax.setRequestHeader("room-id", roomId.toString());
      ajax.setRequestHeader("Authorization", `Bearer ${_API_TOKEN}`);
      ajax.send(formData);
    },
    uploadFromUnsplash: async (unSplashUrl: string, fileName: string, inAlbum?: boolean, compressionRequired?: boolean) => {
      const response = await call("POST", `files/unsplash/download`, {
        fileName,
        unSplashUrl,
        inAlbum,
        compressionRequired: !!compressionRequired
      }, { headerRoomId: _ROOM_ID })

      if (response?.ok) {
        return await response.json() as SpaceFile
      }
      return null;
    },
    getAll: async (spaceId: number, currentRoomId: number | undefined) => {
      const response = await call("GET", `files?space_id=${spaceId}`, undefined, {
        headerRoomId: currentRoomId
      })

      if (response?.ok)
        return await response.json() as SpaceFile[]

      return []
    },
    delete: async (id: number) => {
      await call("DELETE", `files?id=${id}`, undefined, { headerRoomId: _ROOM_ID })
    },
    get: async (id: number) => {
      const response = await call("GET", `files/id?space_file_id=${id}`, undefined, { headerRoomId: _ROOM_ID })

      if (response?.ok)
        return await response.json() as SpaceFile

      return null

    }
  },
  role: {
    getAll: async () => {
      const response = await call("GET", 'role')
      if (response?.ok)
        return await response.json() as Role[]

      return []
    },
  },
  log: {
    add: async (message: string, details: object, participantId?: number) => {
      await call("POST", "public/ui-log-events", {
        spaceId: _SPACE_ID,
        roomId: _ROOM_ID || null,
        participantId: participantId || null,
        browser: _BROWSER_INFO,
        message,
        stackTrace: JSON.stringify(details),
        logTime: new Date()
      })
    }
  },
  partner: {
    eventMobi: async (externalId: string) => {
      const response = await call('POST', 'public/event-mobi/people', {
        eventId: 48202,
        externalId,
      });

      if (response?.ok)
        return await response.json();

      return null;
    },
  },
  app: {
    getApplicationImages: async (categoryName: "master_emojis", groupName: string) => {
      const response = await call("GET", `public/application-images/${categoryName}/${groupName}`)

      if (!response || !response.ok)
        return null

      return await response.json() as ApplicationImagesList
    },
  },
  analytics: {
    getCheckInAndMeetingsReport: async (startDate: string, endDate: string) => {
      const response = await call("POST", 'v4/room_session/analytics/online-availability-vs-inside-meeting', {
        newParticipantIds: false,
        startDate,
        endDate
      })

      if (response?.ok) {
        return await response.json() as Analytics_CheckIn_And_Meeting[]
      }

      return []
    },
    getCheckInReport: async (startDate: string, endDate: string, userId?: number) => {
      const response = await call("POST", 'v4/user/checkin-audit/search', {
        userId: userId || null,
        startDate,
        endDate
      })

      if (response?.ok) {
        return await response.json() as Analytics_CheckIn[]
      }

      return []
    },
    getUserCheckInReport: async (checkinDate: string, userId: number) => {
      const response = await call("POST", 'v4/user/checkin-audit/details/search', {
        userId,
        checkinDate,
      })

      if (response?.ok) {
        return await response.json() as Analytics_CheckIn_Details[]
      }

      return []
    },
    getMeetingsReport: async (startDate: string, endDate: string, userId?: number) => {
      const response = await call("POST", 'v4/room_session/search', {
        userId: userId || null,
        startDate,
        endDate
      })

      if (response?.ok) {
        return await response.json() as Analytics_Meeting[]
      }

      return []
    },
    getUserMeetingsReport: async (date: string, userId: number) => {
      const response = await call("POST", 'v4/room_session/details/search', {
        userId,
        startDate: date,
        endDate: date
      })

      if (response?.ok) {
        return await response.json() as Analytics_Meeting_Details[]
      }

      return []
    },
    getRoomsReport: async (startDate: string, endDate: string) => {
      const response = await call("POST", 'v4/room_session/analytics/group-by-room', {
        startDate,
        endDate
      })

      if (response?.ok) {
        return await response.json() as Analytics_Room[]
      }

      return []
    },
    getRoomSessionsReport: async (roomId: number, startDate: string, endDate: string) => {
      const response = await call("POST", 'v4/room_session/analytics/by-room-id', {
        roomId,
        startDate,
        endDate
      })

      if (response?.ok) {
        return await response.json() as Analytics_Room_Session[]
      }

      return []
    },
    getSessionParticipantsReport: async (roomId: number, roomSessionId: number) => {
      const response = await call("GET", `v4/room/participant-activity?room_id=${roomId}&room_session_id=${roomSessionId}`)

      if (response?.ok) {
        return await response.json() as Analytics_Session_Participant[]
      }

      return []
    },
  }
};

export default api