import { useEffect, useRef } from "react";

export const usePrevious = <T extends unknown>(value: T): T | null => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  if (ref.current)
    return ref.current

  return null
};