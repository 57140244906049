import React from 'react';
import styles from './Loader.module.scss';
import Backdrop from '../Backdrop/Backdrop';
import Typography from '../Typography/Typography';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import classNames from 'classnames';

interface Props {
  message?: string
  inline?: boolean
  iconSize?: number
  className?: string
  justIcon?: boolean
};

export default function Loader({ message = "Loading...", inline, iconSize = 200, className, justIcon }: Props) {
  const getContent = () => (
    <div className={classNames(styles.content, className)}>
      <AnimatedLogo size={iconSize + 56} />
      {!justIcon &&
        <Typography
          className={styles.message}
          color={inline ? undefined : "white"}
        >
          {message}
        </Typography>
      }
    </div>
  )

  if (inline)
    return getContent()

  return (
    <Backdrop className={styles.loading}>
      {getContent()}
    </Backdrop>
  );
}
