import React from 'react'
import Lottie from "lottie-react";
import animationData from '../../../assets/lotties/AnimatedLogo.json'

interface Props {
  size?: number
  stopped?: boolean
}

export default function AnimatedLogo({ size = 128, stopped = false }: Props) {
  return (
    <div style={{ width: size, height: size }}>
      <Lottie
        animationData={animationData}
        autoplay={!stopped}
      />
    </div>
  )
}