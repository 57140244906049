export const isDEV = window.b0133a04c2da_isDEV
export const enabledDebugging = window.b0133a04c2da_enabledDebugging

export const apiUrl = isDEV ? "https://dev-api-02-08-2021.meetonbubble.com" : "https://api-prod-af5deac494f1ce30bba02376ac8f6161.meetonbubble.com"
export const apiTenant = isDEV ? "DEV_DEFAULT" : "PROD_DEFAULT"
export const apiApplicationKey = isDEV ? "18b267e5551fadh9347c7bf1116827fe" : "d5c31b022e8c1e25b35f8434062d1a85"
export const apiApplicationSecret = isDEV ? "6bef9e11abd9513a04c2da6b1fcdab57" : "f8b01393489dc5f0f291aae1bb1f8aef"
export const uiLogAPIToken = isDEV ? "b0rrrb858b3ghj1c9ec8aakkjhasdasdghjke568mffjgjy7015c34thy125930df5768" : "gt678nhj1c9ec8as568klwqeryghjke568mffjgjy7015c34thy12dt57jf30kl"

export const SPACE_ROOT_ELEMENT_ID = "space_root"

export const websocketUrl = isDEV ? "wss://dev-websocket.meetonbubble.com?application=MEET_ON_BUBBLE&authorization=" :
  "wss://prod-websocket.meetonbubble.com/?application=MEET_ON_BUBBLE&authorization="

export const USE_LOCAL_SOCKET = false

export const IP_LOCATION_API_KEY = 'bef40e5670e04a9b87c7fec478550c26'

export const socketIoServerUrl = isDEV ?
  (USE_LOCAL_SOCKET ?
    "http://localhost:3001" :
    "wss://socket-io-server.dev.meetonbubble.com"
  )
  : "wss://socket-io-server.meetonbubble.com"

export const COMET_CHAT_APP_ID = isDEV ? "2398821c74af7a15" : "2406404f43fb134c"
export const COMET_CHAT_REGION = isDEV ? "us" : "us"

export const MANUAL_DEVICE_DETECTION_DISABLED = true