export const DEFAULT_ASPECT_RATIO = 16 / 9;
export const DEFAULT_ROOM_BG = "/assets/img/room-default-bg.jpeg"
export const DEFAULT_SPACE_BG = "/assets/img/space-default-bg.jpg"
export const DEFAULT_SPACE_LOGO = "/assets/img/logo512.png"
export const NAME_LOGO_WHITE = "/assets/img/logo-name.png"
export const NAME_LOGO_DARK = "/assets/img/logo-name-dark.png"
export const STAR_EYE_EMOJI = "/assets/img/star-eye-emoji.png"
export const PARTICIPANT_SIZE = 120
export const QUERY_PARAM_EMAIL = "e"
export const QUERY_PARAM_NAME = "n"
export const QUERY_PARAM_PARTNER_REFERRER = "ref"
export const QUERY_PARAM_PASSWORD = "p"
export const QUERY_PARAM_ROOM_ID = "r"
export const QUERY_PARAM_SPACE_URL_SUFFIX = "s"
export const QUERY_PARAM_LOGIN_REF_URL = "refUrl"
export const QUERY_PARAM_TOKEN = "token"
export const QUERY_PARAM_EXTRA_INVITE_MEMBER_TOKEN = "invite_member_token"
export const QUERY_PARAM_IS_NEW_SIGN_UP = "newUserSignUp"
export const QUERY_PARAM_TEMPLATE_SPACE_URL = "template"
export const QUERY_PARAM_TYPE = "t"
export const QUERY_PARAM_TYPE_GUEST = "g"
export const QUERY_PARAM_TYPE_MEMBER = "m"
export const QUERY_PARAM_AUTO_LOGIN = "a"
export const QUERY_PARAM_PARTNER = "partner"
export const QUERY_PARAM_USER_ID = "uId"
export const BUBBLE_TRANSITION_DURATION = 700
export const ROOM_SIDEBAR_ANIMATION_DURATION = 500
export const LINK_PIN_HEIGHT = 68
export const LOAD_ROOM_DATA_TIMEOUT_AT_JOIN_TIME = 4 * 1000
export const MAX_DISCONNECTION_TIME = 60 * 1000
export const SYNC_INTERVAL = 20 * 1000
export const SYNC_ERROR_CALL_TIMEOUT = 10 * 1000
export const HEARTBEAT_INTERVAL = 30 * 1000
export const WEBSOCKET_HEALTH_CHECK_INTERVAL = 20 * 1000
export const WEBSOCKET_HANDSHAKE_INTERVAL = 20 * 1000
export const JOINED_CALL_FIRST_FEW_SECONDS = 5 * 1000

/**
 * Maximum amount of concurrently subscribed participants.
 */
 export const MAX_SUBSCRIBED_PARTICIPANTS = 10;
 export const MAX_SPACE_URL_LENGTH = 100
 export const MAX_SPACE_NAME_LENGTH = 30
 export const MIN_SPACE_URL_LENGTH = 2
 export const MIN_SPACE_NAME_LENGTH = 5

export const LOCKED_SPACE_DEFAULT_MESSAGE = "I'm unavailable at the moment."

export const FLOOR_BUBBLE_SIZE = 48
export const FLOOR_ROOM_PADDING = 12
export const FLOOR_DIMENSION_WIDTH = 1600
export const FLOOR_DIMENSION_HEIGHT = 900

export const PIN_IMAGE_DEFAULT_FILE_ID = -1

export const PANZOOM_EXCLUDE_CLASS = "panzoom-exclude"

export const DAILY_FRAME_WRAPPER_ID = "daily-frame-wrapper"

export const COMET_CHAT_WIDTH = "360px"