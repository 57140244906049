// color variables for light theme

import { BUBBLE_TRANSITION_DURATION, ROOM_SIDEBAR_ANIMATION_DURATION } from "../../constants";

export const lightStyles: any = {
  //SETTINGS
  '--room-sidebar-width': "360px",
  '--room-sidebar-animation': `${ROOM_SIDEBAR_ANIMATION_DURATION}ms`,
  '--bubble-transition-duration': `${BUBBLE_TRANSITION_DURATION}ms`,

  //CONTEXTUAL
  '--color-dark-background': 'rgb(var(--PCA-1100))',
  '--color-dark-text': 'rgb(var(--white))',
  '--color-dark-text-hover': 'rgb(var(--PCB-0200))',

  // BASIC
  '--white': '255, 255, 255', // #ffffff
  '--black': '0, 0, 0', // #000000

  '--color-white': 'rgb(var(--white))',
  '--color-lightest-gray': 'rgb(var(--GC-0050))', //#f4f5f7
  '--color-light-gray': 'rgb(var(--GC-0100))', //#eaebf0
  '--color-gray': 'rgb(var(--GC-0600))', //#535e86
  '--color-dark-gray': 'rgb(var(--GC-0900))', //#3c4265
  '--color-primary-a': 'rgb(var(--PCA-0800))',
  '--color-primary-b': 'rgb(var(--PCB-0800))',
  '--color-accent': 'rgb(var(--AC-0700))', //#2451CE
  '--color-accent-background': 'rgb(var(--AC-0100))', //#E8EBFB
  '--color-success': 'rgb(var(--SC-0700))',
  '--color-info': 'rgb(var(--IC-0800))', //#1C80C7
  '--color-warning': 'rgb(var(--WC-1000))', //#E47D1D
  '--color-danger': 'rgb(var(--DC-0700))',
  '--color-danger-bold': 'rgb(var(--DC-1000))',

  // BISCAY
  '--PCA-1100': '31, 34, 56', // #1F2238
  '--PCA-1000': '23, 40, 91', // #17285b
  '--PCA-0900': '33, 56, 114', // #213872
  '--PCA-0800': '40, 66, 127', // #28427f
  '--PCA-0700': '49, 75, 138', // #314b8a
  '--PCA-0600': '55, 83, 147', // #375393
  '--PCA-0500': '86, 107, 161', // #566ba1
  '--PCA-0400': '116, 134, 177', // #7486b1
  '--PCA-0300': '154, 167, 199', // #9aa7c7
  '--PCA-0200': '193, 202, 222', // #c1cade
  '--PCA-0100': '231, 234, 241', // #e7eaf1
  '--PCA-0050': '243, 244, 248', //#f3f4f8
  // CARNATION RED
  '--PCB-1000': '201, 32, 45', // #c9202d
  '--PCB-0900': '216, 45, 57', // #d82d39
  '--PCB-0800': '229, 52, 65', // #e53441
  '--PCB-0700': '248, 62, 72', // #f83e48
  '--PCB-0600': '255, 70, 72', // #ff4648
  '--PCB-0500': '255, 89, 97', // #ff5961
  '--PCB-0400': '241, 122, 129', // #f17a81
  '--PCB-0300': '247, 159, 165', // #f79fa5
  '--PCB-0200': '255, 209, 217', // #ffd1d9
  '--PCB-0100': '255, 237, 241', // #ffedf1
  '--PCB-0050': '255, 246, 248', // #fff6f8
  // ACCENT COLORS
  '--AC-1000': '0, 40, 163', // #0028a3
  '--AC-0900': '2, 60, 182', // #023cb6
  '--AC-0800': '21, 70, 194', // #1546c2
  '--AC-0700': '36, 81, 206', // #2451ce
  '--AC-0600': '43, 89, 217', // #2b59d9
  '--AC-0500': '84, 115, 224', // #5473e0
  '--AC-0400': '118, 142, 229', // #768ee5
  '--AC-0300': '159, 174, 237', // #9faeed
  '--AC-0200': '198, 205, 244', // #c6cdf4
  '--AC-0100': '232, 235, 251', // #e8ebfb
  '--AC-0050': '243, 245, 253', // #f3f5fd
  // SHADES OF GREY
  '--GC-1000': '45, 50, 83', // #2d3253
  '--GC-0900': '60, 66, 101', // #3c4265
  '--GC-0800': '67, 76, 114', // #434c72
  '--GC-0700': '76, 86, 126', // #4c567e
  '--GC-0600': '83, 94, 134', // #535e86
  '--GC-0500': '107, 117, 150', // #6b7596
  '--GC-0400': '133, 142, 168', // #858ea8
  '--GC-0300': '166, 174, 194', // #a6aec2
  '--GC-0200': '200, 205, 219', // #c8cddb
  '--GC-0100': '234, 235, 240', // #eaebf0
  '--GC-0050': '244, 245, 247', // #f4f5f7
  // INFORMATION COLORS
  '--IC-1000': '20, 79, 144', // #144f90
  '--IC-0900': '25, 111, 179', // #196fb3
  '--IC-0800': '28, 128, 199', // #1c80c7
  '--IC-0700': '31, 146, 219', // #1f92db
  '--IC-0600': '32, 160, 234', //  #20a0ea
  '--IC-0500': '51, 174, 238', // #33aeee
  '--IC-0400': '82, 188, 240', // #52bcf0
  '--IC-0300': '129, 207, 246', // #81cff6
  '--IC-0200': '179, 226, 250', // #b3e2fa
  '--IC-0100': '225, 244, 253', //  #e1f4fd
  // SUCCESS COLORS
  '--SC-1000': '0, 93, 3', //  #005d03
  '--SC-0900': '2, 124, 27', //  #027c1b
  '--SC-0800': '23, 141, 39', // #178d27
  '--SC-0700': '38, 159, 50', // #269f32
  '--SC-0600': '48, 174, 59', //  #30ae3b
  '--SC-0500': '84, 186, 90', //  #54ba5a
  '--SC-0400': '116, 198, 119', //  #74c677
  '--SC-0300': '157, 214, 158', //  #9dd69e
  '--SC-0200': '196, 230, 196', // #c4e6c4
  '--SC-0100': '230, 245, 231', //  #e6f5e7
  // WARNING COLORS
  '--WC-1000': '228, 125, 29', // #e47d1d
  '--WC-0900': '234, 164, 44', // #eaa42c
  '--WC-0800': '237, 187, 53', // #edbb35
  '--WC-0700': '239, 210, 62', // #efd23e
  '--WC-0600': '239, 226, 66', //  #efe242
  '--WC-0500': '242, 231, 93', // #f2e75d
  '--WC-0400': '244, 236, 121', // #f4ec79
  '--WC-0300': '247, 241, 158', // #f7f19e
  '--WC-0200': '250, 247, 197', // #faf7c5
  '--WC-0100': '253, 252, 231', //  #fdfce7
  // DESTRUCTIVE COLORS
  '--DC-1000': '207, 31, 15', //  #cf1f0f
  '--DC-0900': '232, 49, 26', //  #e8311a
  '--DC-0800': '246, 57, 31', //  #f6391f
  '--DC-0700': '255, 64, 36', //  #ff4024
  '--DC-0600': '255, 70, 40', // #ff4628
  '--DC-0500': '255, 103, 71', // #ff6747
  '--DC-0400': '255, 133, 104', // #ff8568
  '--DC-0300': '255, 168, 147', // #ffa893
  '--DC-0200': '255, 203, 189', // #ffcbbd
  '--DC-0100': '253, 233, 232', // #fde9e8

  '--text-color-primary-a': 'rgb(var(--PCA-0800))',
  '--text-color-primary-b': 'rgb(var(--PCB-0800))',
  '--text-color-accent': 'rgb(var(--AC-0800))',
  '--text-color-gray': 'rgb(var(--GC-0500))',
  '--text-color-info': 'rgb(var(--IC-0800))',
  '--text-color-success': 'rgb(var(--SC-0800))',
  '--text-color-warning': 'rgb(var(--WC-1000))',
  '--text-color-danger': 'rgb(var(--DC-1000))', //#cf1f0f
  '--text-color-white': 'rgb(var(--white))',
  '--text-color-black': 'rgb(var(--black))',

  '--badge-primary-b': 'rgb(var(--PCB-0800))',
  '--badge-danger': 'rgb(var(--DC-0800))',
  '--badge-primary-a': 'rgb(var(--PCA-0800))',

  '--p-button-a-box-color': 'rgb(var(--PCA-0800))',
  '--p-button-a-text-color': 'rgb(var(--white))',
  '--p-button-a-chevron-color': 'rgb(var(--GC-0100))',
  '--p-button-a-hover-box-color': 'rgb(var(--PCA-1000))',
  '--p-button-a-hover-text-color': 'rgb(var(--white))',
  '--p-button-a-hover-chevron-color': 'rgb(var(--GC-0100))',

  '--p-button-b-box-color': 'rgb(var(--PCB-0800))',
  '--p-button-b-text-color': 'rgb(var(--white))',
  '--p-button-b-chevron-color': 'rgb(var(--GC-0100))',
  '--p-button-b-hover-box-color': 'rgb(var(--PCB-1000))',
  '--p-button-b-hover-text-color': 'rgb(var(--white))',
  '--p-button-b-hover-chevron-color': 'rgb(var(--GC-0100))',

  '--sec-button-box-color': 'rgb(var(--GC-0100))',
  '--sec-button-text-color': 'rgb(var(--GC-1000))',
  '--sec-button-chevron-color': 'rgb(var(--GC-0500))',
  '--sec-button-hover-box-color': 'rgb(var(--GC-0200))',
  '--sec-button-hover-text-color': 'rgb(var(--GC-1000))',
  '--sec-button-hover-chevron-color': 'rgb(var(--GC-0500))',

  '--link-icon-color': 'rgb(var(--AC-0700))',
  '--link-text-color': 'rgb(var(--AC-0700))',
  '--link-chevron-color': 'rgb(var(--GC-0500))',
  '--link-hover-box-color': 'rgb(var(--GC-0100))',
  '--link-hover-icon-color': 'rgb(var(--AC-0700))',
  '--link-hover-text-color': 'rgb(var(--AC-0700))',
  '--link-hover-chevron-color': 'rgb(var(--GC-0500))',

  '--des-button-text-color': 'rgb(var(--DC-1000))',
  '--des-button-hover-box-color': 'rgb(var(--DC-0100))',
  '--des-button-hover-text-color': 'rgb(var(--DC-1000))',

  '--text-box-normal-bg': 'rgb(var(--white))',
  '--text-box-error-border': 'rgb(var(--DC-1000))',
  '--text-box-normal-border': 'rgb(var(--GC-0200))',
  '--text-box-normal-text-color': 'rgb(var(--GC-0500))',
  '--text-box-error-text-color': 'rgb(var(--DC-1000))',
  '--text-box-hover-bg': 'rgb(var(--white))',
  '--text-box-hover-border': 'rgb(var(--PCA-1000))',
  '--text-box-hover-text-color': 'rgb(var(--GC-0500))',
  '--text-box-focussed-bg': 'rgb(var(--white))',
  '--text-box-focussed-outline': 'rgb(var(--PCA-0100))',
  '--text-box-focussed-border': 'rgb(var(--PCA-1000))',
  '--text-box-focussed-text-color': 'rgb(var(--GC-0500))',
  '--text-box-disabled-bg': 'rgb(var(--GC-0100))',
  '--text-box-disabled-border': 'rgb(var(--GC-0200))',
  '--text-box-disabled-text-color': 'rgb(var(--GC-0400))',
  '--text-box-with-value-bg': 'rgb(var(--white))',
  '--text-box-with-value-border': 'rgb(var(--PCA-1000))',
  '--text-box-with-value-outline': 'rgb(var(--PCA-0100))',
  '--text-box-with-value-text-color': 'rgb(var(--GC-1000))',
  '--text-box-with-value-close-color': 'rgb(var(--GC-0500))',

  '--checkbox-icon-color': 'rgb(var(--GC-1000))',
  '--checkbox-text-color': 'rgb(var(--GC-1000))',
  '--checkbox-hover-bg': 'rgb(var(--GC-0050))',
  '--checkbox-hover-icon-color': 'rgb(var(--GC-1000))',
  '--checkbox-hover-text-color': 'rgb(var(--GC-1000))',
  '--checkbox-selected-bg': 'rgb(var(--AC-0050))',
  '--checkbox-selected-border': 'rgb(var(--AC-0100))',
  '--checkbox-selected-icon-color': 'rgb(var(--AC-0700))',
  '--checkbox-selected-text-color': 'rgb(var(--GC-1000))',
  '--checkbox-disabled-icon-color': 'rgb(var(--GC-0400))',
  '--checkbox-disabled-text-color': 'rgb(var(--GC-0400))',

  '--radio-button-icon-color': 'rgb(var(--GC-1000))',
  '--radio-button-text-color': 'rgb(var(--GC-1000))',
  '--radio-button-hover-bg': 'rgb(var(--GC-0100))',
  '--radio-button-hover-icon-color': 'rgb(var(--AC-1000))',
  '--radio-button-hover-text-color': 'rgb(var(--GC-1000))',
  '--radio-button-selected-bg': 'rgb(var(--AC-0050))',
  '--radio-button-selected-border': 'rgb(var(--AC-0100))',
  '--radio-button-selected-icon-color': 'rgb(var(--AC-0700))',
  '--radio-button-selected-text-color': 'rgb(var(--GC-1000))',
  '--radio-button-disabled-icon-color': 'rgb(var(--GC-0400))',
  '--radio-button-disabled-text-color': 'rgb(var(--GC-0400))',

  '--login-modal-box-color': 'rgba(var(--white), 0.98)',
  '--login-modal-action-bar': 'rgb(var(--GC-0050))',
  '--login-modal-dropdown-border-color': 'rgba(var(--GC-0200))',
  '--login-modal-dropdown-icon-color': 'rgb(var(--PCB-0800))',
  '--login-modal-dropdown-divider-color': 'rgb(var(--GC-0200))',
  '--login-modal-dropdown-text-color': 'rgb(var(--GC-1000))',
  '--login-modal-dropdown-chevron-color': 'rgb(var(--GC-0400))',

  '--flyout-wa-box-color': 'rgba(var(--white), 0.98)',
  '--flyout-wa-close-color': 'rgb(var(--GC-0500))',
  '--flyout-wa-header-color': 'rgb(var(--PCA-1000))',
  '--flyout-wa-body-text-color': 'rgb(var(--GC-1000))',
  '--flyout-wa-action-bar': 'rgb(var(--GC-0050))',

  '--flyout-woa-box-color': 'rgba(var(--white), 0.98)',
  '--flyout-woa-close-color': 'rgb(var(--GC-0500))',
  '--flyout-woa-header-color': 'rgb(var(--PCA-1000))',
  '--flyout-woa-body-text-color': 'rgb(var(--GC-1000))',
  '--flyout-woa-alert': 'rgb(var(--PCB-0800))',

  '--toast-box-color': 'rgba(var(--white), 0.98)',
  '--toast-close-color': 'rgb(var(--GC-0500))',
  '--toast-action-bar-bg': 'rgb(var(--GC-0050))',
  '--toast-header-info-color': 'rgb(var(--IC-800))',
  '--toast-header-success-color': 'rgb(var(--SC-800))',
  '--toast-header-alert-color': 'rgb(var(--WC-1000))',
  '--toast-header-failure-color': 'rgb(var(--DC-1000))',
  '--toast-body-text-color': 'rgb(var(--GC-1000))',

  '--slider-bg': 'rgba(var(--white), 0.96)',
  '--slider-close-bg': 'rgba(var(--white), 0.96)',
  '--slider-close-icon-color': 'rgb(var(--GC-0600))',
  '--segmented-tabs-def-bg': 'rgb(var(--GC-0600))',
  '--segmented-tabs-def-text': 'rgb(var(--white))',
  '--segmented-tabs-selected-bg': 'rgb(var(--white))',
  '--segmented-tabs-selected-border': 'rgb(var(--GC-0600))',
  '--Segmented-tabs-selected-text': 'rgb(var(--GC-0900))',

  '--chat-slider-msg-header-time': 'rgb(var(--GC-1000))',
  '--chat-slider-msg-body-text': 'rgb(var(--GC-0900))',
  '--chat-slider-msg-divider': 'rgb(var(--GC-0100))',
  '--chat-slider-reply-tab-selected-bg': 'rgb(var(--GC-0600))',
  '--chat-slider-reply-tab-selected-text': 'rgb(var(--white))',
  '--chat-slider-reply-tab-default-text': 'rgb(var(--GC-0600))',
  '--chat-slider-reply-send-icon-color': 'rgb(var(--PCB-0800))',
  '--chat-slider-reply-placeholder-text-color': 'rgb(var(--GC-0400))',

  '--people-slider-header-text': 'rgb(var(--GC-0900))',
  '--people-slider-speaker-icon': 'rgb(var(--GC-0500))',
  '--people-slider-more-options-icon': 'rgb(var(--GC-0500))',

  '--resources-slider-header-text': 'rgb(var(--GC-0900))',
  '--resources-slider-more-options-icon': 'rgb(var(--GC-0500))',

  '--people-card-bg': 'rgb(var(--white))',
  '--people-card-header-text': 'rgb(var(--PCA-1000))',
  '--people-card-link-text': 'rgb(var(--AC-0700))',
  '--people-card-body-text': 'rgb(var(--GC-1000))',
  '--people-card-close-icon': 'rgb(var(--GC-0500))',
  '--people-card-edit-icon': 'rgb(var(--GC-0500))',
  '--people-card-action-bar-bg': 'rgb(var(--GC-0050))',
  '--people-card-action-def-icon': 'rgb(var(--GC-0900))',
  '--people-card-action-logout-icon': 'rgb(var(--DC-1000))',
  '--people-card-action-divider': 'rgb(var(--GC-0200))',

  '--control-tray-box-bg': 'rgba(var(--white), 0.98)',
  '--control-tray-power-bg': 'rgb(var(--DC-0100))',
  '--control-tray-power-icon-color': 'rgb(var(--DC-1000))',
  '--control-tray-power-hover-bg': 'rgb(var(--DC-1000))',
  '--control-tray-power-hover-color': 'rgb(var(--white))',
  '--control-tray-icon-color': 'rgb(var(--GC-0500))',
  '--control-tray-icon-hover-bg': 'rgb(var(--GC-0100))',
  '--control-tray-icon-hover-color': 'rgb(var(--GC-0900))',
  '--control-tray-icon-enable-bg': 'rgb(var(--PCB-0800))',
  '--control-tray-icon-enable-color': 'rgb(var(--white))',
  '--control-tray-tooltip-bg': 'rgb(var(--white))',
  '--control-tray-tooltip-text-color': 'rgb(var(--GC-0900))',
  '--control-tray-chevron-color': 'rgb(var(--GC-0400))',
  '--control-tray-divider-color': 'rgb(var(--GC-0200))',
  '--control-tray-text-color': 'rgb(var(--GC-0900))',
  '--control-tray-more-items-bg': 'rgb(var(--GC-0100))',
  '--control-tray-more-items-icon-color': 'rgb(var(--GC-0900))',

  '--control-tray-rooms-list-bg': 'rgb(var(--white), 0.98)',
  '--control-tray-rooms-border': 'rgb(var(--AC-0200))',
  '--control-tray-rooms-title-text': 'rgb(var(--PCA-1000))',
  '--control-tray-rooms-close-color': 'rgb(var(--black))',
  '--control-tray-rooms-search-box-border': 'rgb(var(--GC-0200))',
  '--control-tray-rooms-search-icon-color': 'rgb(var(--GC-0500))',
  '--control-tray-rooms-search-box-divider-color': 'rgb(var(--GC-0200))',
  '--control-tray-rooms-search-box-text-color': 'rgb(var(--GC-0500))',
  '--control-tray-rooms-divider-color': 'rgb(var(--GC-0600))',
  '--control-tray-rooms-icon-color': 'rgb(var(--GC-0500))',
  '--control-tray-rooms-text-color': 'rgb(var(--GC-1000))',
  '--control-tray-rooms-subtext-color': 'rgb(var(--GC-0500))',
  '--control-tray-rooms-subtext-action-separator-color': 'rgb(var(--GC-0200))',
  '--control-tray-rooms-subtext-action-color': 'rgb(var(--AC-0700))',

  '--control-tray-chevron-enable-color': 'rgb(var(--white))',
  '--control-tray-dropdown-l1-list-bg': 'rgb(var(--white), 0.98)',
  '--control-tray-dropdown-l1-list-border': 'rgb(var(--AC-0200))',
  '--control-tray-dropdown-l1-list-text': 'rgb(var(--GC-1000))',
  '--control-tray-dropdown-l1-list-subtext': 'rgb(var(--GC-0600))',
  '--control-tray-dropdown-l1-list-chevron': 'rgb(var(--GC-0500))',
  '--control-tray-dropdown-l1-list-hover-bg': 'rgb(var(--AC-0050))',
  '--control-tray-dropdown-l2-list-bg': 'rgb(var(--white), 0.98)',
  '--control-tray-dropdown-l2-list-border': 'rgb(var(--AC-0200))',
  '--control-tray-dropdown-l2-list-text': 'rgb(var(--GC-1000))',
  '--control-tray-dropdown-l2-list-selected-bg': 'rgb(var(--AC-0050))',
  '--control-tray-dropdown-l2-list-text-selected': 'rgb(var(--GC-1000))',
  '--control-tray-dropdown-l2-list-selection-icon-color': 'rgb(var(--AC-0700))',

  '--video-ava-inner-border': 'rgb(var(--white))',
  '--video-ava-outer-border': 'rgb(var(--PCB-0500))',

  '--speaker1-inner-border': 'rgb(var(--white))',
  '--speaker1-outer-border-1': 'rgba(var(--white), 0.40)',
  '--speaker1-outer-border-2': 'rgba(var(--white), 0.60)',
  '--speaker1-outer-border-3': 'rgba(var(--white), 0.80)',

  '--speaker2-inner-border': 'rgb(var(--white))',
  '--speaker2-outer-border-1': 'rgba(var(--white), 0.40)',
  '--speaker2-outer-border-2': 'rgba(var(--white), 0.60)',
  '--speaker2-outer-border-3': 'rgba(var(--white), 0.80)',
  '--speaker2-resizing-inner-border': 'rgb(var(--PCB-0800))',
  '--speaker2-resizing-outer-border-1': 'rgba(var(--white), 0.40)',
  '--speaker2-resizing-outer-border-2': 'rgba(var(--white), 0.60)',
  '--speaker2-resizing-outer-border-3': 'rgba(var(--white), 0.80)',

  '--space-info-box-bg': 'rgba(var(--white), 0.88)',
  '--space-info-title-color': 'rgb(var(--PCA-1000))',
  '--space-info-lock-def-color': 'rgb(var(--GC-0500))',
  '--space-info-lock-hover-bg': 'rgb(var(--GC-0100))',
  '--space-info-lock-hover-color': 'rgb(var(--GC-0900))',
  '--space-info-lock-close-color': 'rgb(var(--DC-1000))',
  '--space-info-divider-color': 'rgb(var(--GC-0200))',
  '--space-info-icon-def-color': 'rgb(var(--GC-0500))',
  '--space-info-icon-selected-BG': 'rgb(var(--GC-0100))',
  '--space-info-icon-selected-color': 'rgb(var(--GC-0900))',
  '--space-info-icon-dropdown-bg': 'rgba(var(--white), 0.98)',
  '--space-info-icon-dropdown-border': 'rgb(var(--AC-0200))',
  '--space-info-icon-dropdown-text-color': 'rgb(var(--GC-1000))',
  '--space-info-icon-dropdown-selected-bg': 'rgb(var(--AC-0050))',

  '--zoom-info-box-bg': 'rgba(var(--white), 0.88)',
  '--zoom-info-icon-color': 'rgb(var(--GC-0500))',
  '--zoom-info-text-color': 'rgb(var(--GC-1000))',
  '--zoom-info-divider-color': 'rgb(var(--GC-0200))',

  '--waiting-room-notification-bg': 'rgb(var(--DC-1000))',
  '--waiting-room-notification-border': 'rgb(var(--white))',
  '--waiting-room-notification-color': 'rgb(var(--white))',
  '--waiting-room-flyout-bg': 'rgba(var(--white), 0.98)',
  '--waiting-room-flyout-border': 'rgb(var(--AC-0200))',
  '--waiting-room-flyout-title-color': 'rgb(var(--GC-1000))',
  '--waiting-room-flyout-close-color': 'rgb(var(--black))',
  '--waiting-room-flyout-header-bg': 'rgb(var(--GC-0050))',
  '--waiting-room-flyout-header-title-color': 'rgb(var(--GC-1000))',
  '--waiting-room-flyout-header-action-color': 'rgb(var(--AC-0700))',
  '--waiting-room-flyout-header-bottom-border-color': 'rgb(var(--AC-0200))',
  '--waiting-room-flyout-item-text-color': 'rgb(var(--GC-1000))',
  '--waiting-room-flyout-item-hover-bg': 'rgb(var(--GC-0050))',
  '--waiting-room-flyout-item-remove-color': 'rgb(var(--DC-1000))',
  '--waiting-room-flyout-item-divider-color': 'rgb(var(--AC-0200))',
  '--waiting-room-flyout-item-admit-color': 'rgb(var(--AC-0700))',
};