const HASH_LENGTH_POSITION = 4
const HASH_MIN_LENGTH = 5
const HASH_MAX_LENGTH = 9
const getRandomString = (hashLength: number) => (Math.random() + 1).toString(36).slice(-hashLength);

export const encrypt = (text: string) => {
  const hashLength = Math.floor(Math.random() * (HASH_MAX_LENGTH - HASH_MIN_LENGTH + 1)) + HASH_MIN_LENGTH

  const parts = text.split('')
  let result = getRandomString(hashLength)
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    result += part + getRandomString(hashLength)
  }

  //put the hashLength in HASH_LENGTH_POSITION into the result string
  //0123456789
  return result.slice(0, HASH_LENGTH_POSITION - 1) + hashLength + result.slice(HASH_LENGTH_POSITION - 1)
};

export const decrypt = (encoded: string) => {
  if (!encoded)
    return null

  const hashLengthStr = encoded.slice(HASH_LENGTH_POSITION - 1, HASH_LENGTH_POSITION)

  var reg = /^\d+$/;
  if (!reg.test(hashLengthStr))
    return null

  const hashLength = parseInt(hashLengthStr)
  //remove the hash length
  encoded = encoded.slice(0, HASH_LENGTH_POSITION - 1) + encoded.slice(HASH_LENGTH_POSITION)

  if (encoded.length < (2 * hashLength + 1))
    return null

  let result = ''
  do {
    result += encoded.slice(hashLength, hashLength + 1)
    encoded = encoded.slice(hashLength + 1)
  } while (encoded.length > 0);

  return result
};
