import React, { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';

import styles from './Typography.module.scss';

interface IProps {
  color?: TypographyColor,
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'label' | 'div',
  gutterBottom?: boolean,
  handleClick?: () => void,
  variant?: TypographyVariant,
  className?: string,
  htmlFor?: string,
  weight?: number,
  withNoWrap?: boolean
  style?: CSSProperties
  children?: React.ReactNode
}

const Typography: React.FC<IProps> = ({
  children,
  color,
  gutterBottom = false,
  handleClick,
  component,
  variant = 'body-1',
  weight,
  className = '',
  withNoWrap,
  style,
  ...rest
}) => {
  const Component = useMemo(() => {
    if (component)
      return component;

    switch (variant) {
      case 'header-1': // Heavy / bold
        return 'h1';
      case 'header-2': // Semi-bold / Medium / Reg
        return 'h2';
      case 'header-3': // Semi-bold / Medium / Reg
        return 'h3';
      case 'header-4': // Semi-bold / Medium / Reg
        return 'h4';
      case 'caption': // MEDIUM
        return 'span';
      case 'body-1': // BOLD / Semi-bold / Medium / Reg
      case 'body-2': // BOLD / Semi-bold / Medium / Reg
      default:
        return 'p';
    }
  }, [component, variant]);

  return (
    <Component
      className={classNames(styles.typography, styles[`variant-${variant}`], className, {
        [styles.gutterBottom]: gutterBottom,
        [styles.withNoWrap]: withNoWrap,
        [styles[`color-${color}`]]: !!color
      })}
      onClick={handleClick}
      style={{ ...style, fontWeight: weight }}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Typography