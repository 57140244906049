import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import Alert from "../components/common/Alert/Alert";
import useSocketIo from "../hooks/useSocketIo";
import api from "../utils/api";
import { localStorageProvider } from "../utils/localStorageProvider";
import socket from "../utils/socket";
import url from "../utils/url";
import utilities from "../utils/utilities";
import { ToastContainer, toast, Slide } from 'react-toastify';
import toastStyles from '../components/common/Toast/Toast.module.scss';
import classNames from "classnames";
import useOnSocketMessage from "../hooks/useOnSocketMessage";
import { usePrevious } from "../hooks/usePrevious";
import { CometChat } from "@cometchat-pro/chat";
import { COMET_CHAT_APP_ID, COMET_CHAT_REGION } from "../constants/environment";

const TOAST_CLASS = 'BUBBLE_TOAST'

interface AppState {
  currentParticipant: CurrentParticipant | null
  currentSpace: Space | null
  currentUser: User | null
  currentUserStatus: UserStatus
  dailyPrebuiltEnabled: boolean,
  initializing: boolean
  mySpaces: MySpace[]
  roles: Role[]
  socketStatus: SocketStatus
  socketPrevStatus: SocketStatus | null

  addToMySpaces: (participantId: number, participantType: ParticipantType) => void
  confirmation: (options: ConfirmationOptions) => void
  hideAlert: () => void
  logout: () => void
  setCometChatAuthToken: (authToken: string) => void
  setCurrentParticipantDefaultRoomId: (defaultRoomId: number) => void
  setCurrentUser: (user: User | null) => void
  setCurrentUserStatus: <K extends keyof UserStatus>(part: K, status: UserStatus[K]) => void
  setMySpaces: (mySpaces: MySpace[]) => void
  setRoles: (roles: Role[]) => void
  setUserAccessToken: (accessToken: string) => void
  showAlert: (alert: BubbleAlert) => void
  showToast: (alert: BubbleAlert, onClick?: (event: React.MouseEvent) => void, customId?: string) => void
  updateCurrentUserProfile: (userInfo: User) => void
  updateCurrentUserProfilePicture: (userInfo: User) => void

  callFrameInitiated: boolean
  setCallFrameInitiated: (value: boolean) => void
  dailyPrebuiltIFrameIsReady: boolean
  setDailyPrebuiltIFrameIsReady: (value: boolean) => void
}

const initialState: AppState = {
  currentParticipant: null,
  currentSpace: null,
  currentUser: null,
  currentUserStatus: { custom: '', dynamic: '' as any, static: '' as any },
  dailyPrebuiltEnabled: false,
  initializing: false,
  mySpaces: [],
  roles: [],
  socketStatus: "uninitiated",
  socketPrevStatus: null,

  addToMySpaces: () => { },
  confirmation: () => { },
  hideAlert: () => { },
  logout: () => { },
  setCometChatAuthToken: () => { },
  setCurrentParticipantDefaultRoomId: () => { },
  setCurrentUser: () => { },
  setCurrentUserStatus: () => { },
  setMySpaces: () => { },
  setRoles: () => { },
  setUserAccessToken: () => { },
  showAlert: () => { },
  showToast: () => { },
  updateCurrentUserProfile: () => { },
  updateCurrentUserProfilePicture: () => { },


  callFrameInitiated: false,
  setCallFrameInitiated: () => { },
  dailyPrebuiltIFrameIsReady: false,
  setDailyPrebuiltIFrameIsReady: () => { }
}

export const AppContext = createContext<AppState>(null!);

interface Props {
  children?: React.ReactNode
}
export const AppProvider: React.FC<Props> = ({ children }) => {
  //states
  const [currentUser, setCurrentUser] = useState(initialState.currentUser)
  const [currentUserStatus, setCurrentUserStatus] = useState("")
  const [currentSpace, setCurrentSpace] = useState(initialState.currentSpace)
  const [initializing, setInitializing] = useState(initialState.initializing)
  const [mySpaces, setMySpaces] = useState(initialState.mySpaces)
  const [roles, setRoles] = useState(initialState.roles)
  const [userAccessToken, setUserAccessToken] = useState('')
  const [cometChatAuthToken, setCometChatAuthToken] = useState('')
  const [alert, setAlert] = useState<BubbleAlert | null>(null)
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions | null>(null)
  const { socketStatus } = useSocketIo(userAccessToken)
  const socketPrevStatus = usePrevious(socketStatus)
  const [callFrameInitiated, setCallFrameInitiated] = useState(false)
  const [dailyPrebuiltIFrameIsReady, setDailyPrebuiltIFrameIsReady] = useState(false)

  const dailyPrebuiltEnabled = useMemo(() => {
    return currentSpace?.customerSubscriptionPlanFeature?.maxRoomCount === 9637 || !!currentSpace?.customerSubscriptionPlanFeature?.customerSubscriptionPlanFeaturePermissions?.enableDailyPrebuilt
  }, [currentSpace?.customerSubscriptionPlanFeature?.maxRoomCount, currentSpace?.customerSubscriptionPlanFeature?.customerSubscriptionPlanFeaturePermissions?.enableDailyPrebuilt])

  const currentParticipant = useMemo(() => {
    if (!mySpaces || mySpaces.length === 0 || !currentSpace?.id)
      return null

    const mySpace = mySpaces.find(ms => ms.spaceId === currentSpace?.id)

    if (!mySpace)
      return null

    return {
      id: mySpace.participantId,
      participantType: mySpace.participantType,
      defaultRoomId: mySpace.defaultRoomId
    } as CurrentParticipant

  }, [currentSpace?.id, mySpaces])

  //socket io listener just dispatch
  const onSocketMessage = useCallback((socketMessage: WebsocketMessage) => {
    if (socketStatus !== "connected")
      return

    switch (socketMessage.notificationEventType) {
      case "SPACE_UPDATED":
        setCurrentSpace(socketMessage.space)
        break
      case "SPACE_LOGO_UPDATED":
        setCurrentSpace(prevSpace => {
          if (prevSpace)
            return { ...prevSpace, logo: socketMessage.space.logo }
          else
            return socketMessage.space
        })
        break;
      case "SPACE_BACKGROUND_UPDATED":
        setCurrentSpace(prevSpace => {
          if (prevSpace)
            return { ...prevSpace, background: socketMessage.space.background }
          else
            return socketMessage.space
        })
        break;
      case "SPACE_ACCESS_UPDATED":
        setCurrentSpace(prevSpace => {
          if (prevSpace)
            return { ...prevSpace, spaceAccess: socketMessage.space.spaceAccess }
          else
            return socketMessage.space
        })
        break;
      case "SPACE_LOCK_UPDATED":
        setCurrentSpace(prevSpace => {
          if (prevSpace)
            return { ...prevSpace, spaceAccess: { ...prevSpace.spaceAccess, accessLevelType: socketMessage.space.spaceAccess.accessLevelType } }
          else
            return socketMessage.space
        })
        break;
      case "PARTICIPANT_INFO_UPDATED":
        setCurrentUser(prevUser => {
          if (socketMessage.participant.userId !== prevUser?.userId)
            return prevUser
          else
            return {
              ...prevUser,
              firstName: socketMessage.participant.slimUser?.firstName || prevUser.firstName,
              description: socketMessage.participant.slimUser?.description || prevUser.description,
              linkedInUrl: socketMessage.participant.slimUser?.linkedInUrl || prevUser.linkedInUrl,
              profilePicture256x256URL: socketMessage.participant.slimUser?.profilePicture256x256URL || prevUser.profilePicture256x256URL,
              profilePicture48x48URL: socketMessage.participant.slimUser?.profilePicture48x48URL || prevUser.profilePicture48x48URL,
              profilePicture96x96URL: socketMessage.participant.slimUser?.profilePicture96x96URL || prevUser.profilePicture96x96URL,
              profilePictureURL: socketMessage.participant.slimUser?.profilePictureURL || prevUser.profilePictureURL,
            }
        })
        break;
    }
  }, [socketStatus])
  useOnSocketMessage("APP_ALL", [], onSocketMessage)

  const showToast = (alert: BubbleAlert, onClick?: (event: React.MouseEvent) => void, customId?: string) => {
    toast(
      <Alert
        inline
        onClick={onClick}
        onButtonClick={e => {
          //to avoid the onClick event (in the case of opening the notification window)
          //click the toast div just to dismiss it
          if (e.currentTarget.parentElement) {

            const t = e.currentTarget.parentElement.closest(`.${TOAST_CLASS}`)

            if (t)
              (t as HTMLElement).click()
          }
        }
        }
        {...alert}
      />,
      {toastId: customId}
    );
  }

  //socket io listener socket private message
  const onSocketPrivateMessage = useCallback((socketMessage: WebsocketMessage) => {
    if (socketStatus !== "connected")
      return

    switch ((socketMessage as any).message.type) {
      case "DUPLICATE_CONNECTION":
        if (window._callObject)
          try { window._callObject.leave() } catch (error) { }

        socket.disconnect()

        setCurrentSpace(null)

        setTimeout(() => {
          const rootElm = document.getElementById("root")

          if (!rootElm)
            return

          setAlert(null)
          setConfirmationOptions(null)

          rootElm.innerHTML = "<h3 style='color: white'>You've joined this space from another tab! You can safely close this tab.</h3>"
        }, 500);
        break
      case "REMOVE_FROM_SPACE":
        //showToast(`You've been removed from the space.`);
        setAlert({
          message: `You've been removed from the space. The page will be refreshed in 5 seconds...`,
          noCloseOrCancel: true,
          type: 'warning',
        })
        setTimeout(() => window.location.reload(), 5000);
        break;
    }
  }, [socketStatus])
  useOnSocketMessage("APP_PRIVATE", ["SOCKET_PRIVATE_MESSAGE"], onSocketPrivateMessage)

  const setCurrentParticipantDefaultRoomId = useCallback((defaultRoomId: number) => {
    if (!currentParticipant?.id || !currentSpace?.id)
      return

    setMySpaces(prev => [...prev.map(ms => ms.spaceId !== currentSpace.id || ms.participantId !== currentParticipant.id ? ms : ({
      ...ms,
      defaultRoomId
    }))])
  }, [currentParticipant?.id, currentSpace?.id])

  useEffect(() => {
    api.setCurrentParticipantId(currentParticipant?.id || null)
  }, [currentParticipant])

  //load current space and user
  useEffect(() => {

    const load = async () => {

      const spaceUrlSuffix = url.getSpaceUrlSuffix()

      if (!spaceUrlSuffix)
        return

      setInitializing(true)
      const s = await api.space.get(spaceUrlSuffix)

      //space not found
      if (!s) {
        window.location.href = `space-not-found#${spaceUrlSuffix}`
        return
      }

      window._currentSpaceId = s.id
      api.setSpaceId(s.id)
      setCurrentSpace(s)
      setInitializing(false)
    }

    load()
  }, [])

  const addToMySpaces = useCallback((participantId: number, participantType: ParticipantType) => {
    if (!currentSpace)
      return

    setMySpaces(old => {

      if (old.findIndex(ms => ms.spaceId === currentSpace.id) >= 0) {
        return old.map(ms => ms.spaceId !== currentSpace.id ? ms : {
          ...ms,
          participantType
        })
      }

      const newMySpace: MySpace = {
        participantId,
        participantType,
        spaceId: currentSpace.id,
        spaceLogo: currentSpace.logo!!,
        spaceName: currentSpace.name,
        urlSuffix: currentSpace.urlSuffix
      }
      return [...old, newMySpace]
    })
  }, [currentSpace])

  const logout = useCallback(() => {
    setCurrentUser(null)
    setMySpaces([])
    setUserAccessToken('')
    localStorageProvider.userToken.remove()
  }, [setCurrentUser, setMySpaces, setUserAccessToken])

  const updateCurrentUserProfile = useCallback((userInfo: User) => {
    setCurrentUser(u => {
      if (!u)
        return u

      let newUser: User = {
        ...u,
        firstName: userInfo.firstName,
        description: userInfo.description,
        linkedInUrl: userInfo.linkedInUrl
      }

      return newUser
    })
  }, [setCurrentUser])

  const updateCurrentUserProfilePicture = useCallback((userInfo: User) => {
    setCurrentUser(u => {
      if (!u)
        return u

      let newUser: User = {
        ...u,
        profilePictureURL: userInfo.profilePictureURL || u.profilePictureURL,
        profilePicture48x48URL: userInfo.profilePicture48x48URL || u.profilePicture48x48URL,
        profilePicture96x96URL: userInfo.profilePicture96x96URL || u.profilePicture96x96URL,
        profilePicture256x256URL: userInfo.profilePicture256x256URL || u.profilePicture256x256URL
      }

      return newUser
    })
  }, [setCurrentUser])

  const _SetCurrentUserStatus = useCallback(<K extends keyof UserStatus>(part: K, status: UserStatus[K]) => setCurrentUserStatus(prev => {
    let prevStatus = utilities.parseUserStatus(prev)

    prevStatus[part] = status

    return utilities.userStatusToString(prevStatus)
  }), [])

  const showAlert = useCallback((alert: BubbleAlert) => setAlert(alert), [])
  const hideAlert = () => setAlert(null)

  const [cometChatIsInitiated, setCometChatIsInitiated] = useState(false)

  //Comet chat init
  useEffect(() => {
    if (cometChatIsInitiated)
      return

    const setupCometChat = () => {
      setCometChatIsInitiated(true)

      let appSetting = new CometChat.AppSettingsBuilder()
        // .subscribePresenceForAllUsers()
        .setRegion(COMET_CHAT_REGION)
        .autoEstablishSocketConnection(true)
        .build();

      CometChat.init(COMET_CHAT_APP_ID, appSetting)
        .then(
          (initialized: boolean) => {
            console.log("C1050 Initialization completed successfully", initialized);

            const updateUserData = (user: CometChat.User) => {
              if (!currentUser)
                return

              if (currentUser.profilePicture96x96URL)
                user.setAvatar(currentUser.profilePicture96x96URL)

              user.setName(currentUser.firstName)
              user.setMetadata(JSON.stringify({
                status: currentUserStatus,
                isSpaceAdmin: currentParticipant?.participantType === "SPACE_ADMIN",
                roomPosition: undefined,
                roomId: undefined
              }))
              CometChat.updateCurrentUserDetails(user)
            }

            CometChat.getLoggedinUser().then(user => {

              if (!user) {
                CometChat
                  .login(cometChatAuthToken)
                  .then(
                    (user: CometChat.User) => {
                      //console.log("C1050 Login Successful:", user);
                      updateUserData(user)
                    }, (error: CometChat.CometChatException) => {
                      console.log("C1050 Login failed with exception:", error);
                    }
                  );
              }
              else {
                //console.log("C1050 User is logged in.", user)
                updateUserData(user)
              }
            }, (error: CometChat.CometChatException) => {
              console.log("C1050 Some Error Occurred", error);
            }
            );

          }, (error: CometChat.CometChatException) => {
            console.log("C1050 Initialization failed with error:", error);
          }
        )
    }

    if (cometChatAuthToken) {
      setupCometChat()
    }

    //     return () => {
    // CometChat.close
    //     }
  }, [currentUser, cometChatAuthToken, currentParticipant?.participantType, currentUserStatus, cometChatIsInitiated])

  return <AppContext.Provider
    value={{
      currentParticipant,
      currentSpace,
      currentUser,
      currentUserStatus: utilities.parseUserStatus(currentUserStatus),
      dailyPrebuiltEnabled,
      initializing: initializing,
      mySpaces,
      roles,

      socketStatus,
      socketPrevStatus,

      addToMySpaces,
      confirmation: setConfirmationOptions,
      hideAlert,
      logout,
      setCometChatAuthToken,
      setCurrentParticipantDefaultRoomId,
      setCurrentUser,
      setCurrentUserStatus: _SetCurrentUserStatus,
      setMySpaces,
      setRoles,
      setUserAccessToken,
      showAlert,
      showToast,
      updateCurrentUserProfile,
      updateCurrentUserProfilePicture,


      callFrameInitiated,
      setCallFrameInitiated,
      dailyPrebuiltIFrameIsReady,
      setDailyPrebuiltIFrameIsReady
    }}>
    {children}

    <ToastContainer
      limit={5}
      hideProgressBar
      newestOnTop
      className={toastStyles.container}
      toastClassName={classNames(toastStyles.toast, TOAST_CLASS)}
      bodyClassName={toastStyles.toastBody}
      //theme="dark"
      transition={Slide}
      autoClose={5000}
      closeButton={false}
      position="top-right"
      stacked
    />

    {alert &&
      <Alert
        {...alert}
        onClose={hideAlert}
      />
    }

    {confirmationOptions && (
      <Alert
        type='warning'
        title={confirmationOptions?.title}
        message={confirmationOptions?.message || 'Are you sure?'}
        onClose={() => setConfirmationOptions(null)}
        primaryButtonText={confirmationOptions?.submitLabel || "Ok"}
        onPrimaryButtonClick={confirmationOptions?.submitCallback}
        secondaryButtonText={confirmationOptions?.cancelLabel || "Cancel"}
        //to make sure cancel button is shown
        onSecondaryButtonClick={confirmationOptions?.cancelCallback || (() => { })}
      />
    )}
  </AppContext.Provider>
}