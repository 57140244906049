import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

import styles from './Button.module.scss';

interface IProps {
  children?: React.ReactNode
  className?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  gutterBottom?: boolean,
  id?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  prefix?: string | ReactElement,
  postfix?: string | ReactElement,
  showLoader?: boolean,
  size?: 'xs' | 'small' | 'normal' | 'large',
  style?: React.CSSProperties,
  type?: 'submit' | 'button',
  variant?: ButtonVariant,
  outline?: boolean
}

const Button: React.FC<IProps> = ({
  children,
  className,
  disabled = false,
  fullWidth,
  gutterBottom,
  id,
  onClick,
  prefix = null,
  postfix = null,
  showLoader = false,
  size = 'normal',
  style = {},
  type = 'button',
  variant = 'secondary',
  outline = false
}) => {
  return (
    <button
      className={classNames(styles.button, styles[variant], styles[size], className, {
        [styles.fullWidth]: fullWidth,
        [styles.gutterBottom]: gutterBottom,
        [styles.outline]: outline,
      })}
      disabled={disabled}
      onClick={e => !showLoader && onClick && onClick(e)}
      style={style}
      type={type}
      data-variant={variant}
      id={id}
    >
      {prefix && (
        <div className={styles.prefix}>
          {prefix}
        </div>
      )}
      {showLoader ? (<Icon name='loading' color='white' />) : children}
      {postfix && (
        <div className={styles.postfix}>
          {postfix}
        </div>
      )}
    </button>
  )
}


export default Button