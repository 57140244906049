
import { encrypt, decrypt } from './crypt';
import {
  QUERY_PARAM_EMAIL,
  QUERY_PARAM_NAME,
  QUERY_PARAM_PARTNER_REFERRER,
  QUERY_PARAM_PASSWORD,
  QUERY_PARAM_ROOM_ID,
  QUERY_PARAM_SPACE_URL_SUFFIX,
  QUERY_PARAM_TOKEN,
  QUERY_PARAM_TYPE,
  QUERY_PARAM_AUTO_LOGIN,
  QUERY_PARAM_PARTNER,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_TYPE_MEMBER,
  QUERY_PARAM_TYPE_GUEST,
  QUERY_PARAM_LOGIN_REF_URL,
  QUERY_PARAM_TEMPLATE_SPACE_URL,
  QUERY_PARAM_IS_NEW_SIGN_UP,
  QUERY_PARAM_EXTRA_INVITE_MEMBER_TOKEN,
} from "../constants"

const getQueryParam = (name: string) => {
  return new URLSearchParams(window.location.search).get(name)
}

const setQueryParam = (key: string, value: string | undefined) => {
  const params = new URLSearchParams(window.location.search);
  if (value) {
    params.set(key, value);
  } else {
    params.delete(key);
  }
  return params;
}
const url = {
  getSpaceUrlSuffix: () => getQueryParam(QUERY_PARAM_SPACE_URL_SUFFIX),
  getLoginRefURL: () => getQueryParam(QUERY_PARAM_LOGIN_REF_URL),
  getPassword: () => getQueryParam(QUERY_PARAM_PASSWORD),

  getEmail: () => getQueryParam(QUERY_PARAM_EMAIL),
  setEmail: (email: string) => setQueryParam(QUERY_PARAM_EMAIL, email),

  getName: () => getQueryParam(QUERY_PARAM_NAME),
  setName: (name: string) => setQueryParam(QUERY_PARAM_NAME, name),

  getToken: () => getQueryParam(QUERY_PARAM_TOKEN),
  getExtraInviteMemberToken: () => getQueryParam(QUERY_PARAM_EXTRA_INVITE_MEMBER_TOKEN),

  getTemplateSpaceUrl: () => getQueryParam(QUERY_PARAM_TEMPLATE_SPACE_URL),

  getPartnerReferrer: () => getQueryParam(QUERY_PARAM_PARTNER_REFERRER),

  getPartner: () => getQueryParam(QUERY_PARAM_PARTNER),
  removePartner: () => setQueryParam(QUERY_PARAM_PARTNER, undefined),

  getUserId: () => getQueryParam(QUERY_PARAM_USER_ID),
  removeUserId: () => setQueryParam(QUERY_PARAM_USER_ID, undefined),

  getAutoLogin: () => getQueryParam(QUERY_PARAM_AUTO_LOGIN) === "1",
  removeAutoLogin: () => setQueryParam(QUERY_PARAM_AUTO_LOGIN, undefined),

  getIsNewSignUp: () => {
    const param = getQueryParam(QUERY_PARAM_IS_NEW_SIGN_UP)

    if (param === null)
      return false

    return param === 'true'
  },

  getLoginType: () => {
    const type = getQueryParam(QUERY_PARAM_TYPE)

    if (!type)
      return null

    if (type.toLowerCase() === QUERY_PARAM_TYPE_GUEST)
      return "guest"

    if (type.toLowerCase() === QUERY_PARAM_TYPE_MEMBER)
      return "member"

    return null
  },

  getRoomId: (dontDecrypt?: boolean) => {
    const roomId = getQueryParam(QUERY_PARAM_ROOM_ID);

    if (!roomId)
      return null

    if (dontDecrypt)
      return roomId

    var reg = /^\d+$/;
    if (reg.test(roomId))
      return roomId

    const decryptedRoomId = decrypt(roomId.toString())

    if (!decryptedRoomId || !reg.test(decryptedRoomId))
      return null

    return decryptedRoomId;
  },

  setRoomId: (roomId: string | null) => {
    const roomIdHash = roomId ? encrypt(roomId) : undefined;
    return setQueryParam(QUERY_PARAM_ROOM_ID, roomIdHash);
  }
}

export default url