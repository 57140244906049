import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { uid } from 'uid';

import Emoji from '../components/common/Emoji/Emoji';

import { PARTICIPANT_SIZE } from '../constants';

import { lightStyles, darkStyles, Theme } from '../themes';

import 'react-toastify/dist/ReactToastify.css';

interface IProps {
  isMobile: boolean,
  windowIsFocused: boolean,
  theme: Theme,
  updateTheme: (theme: Theme) => void,
  sidebarOpened: boolean,
  sidebarTab: SidebarState,
  openSidebar: (tab: SidebarState) => void
  closeSidebar: () => void
  internetSpeedMbps?: number
  setInternetSpeed: (speedMbps: number) => void
  toggleFullscreen: () => void,
  fullscreenEnabled: boolean,
  showEmoji: (emoji: Emoji) => void
  bubbleSize: number
  audioRangeSize: number
  setBubbleSize: (value: number) => void
  setAudioRangeSize: (value: number) => void
  floatingCallPosition: Position,
  setFloatingCallPosition: (position: Position) => void
  lastWindowResizeTime: Date
}

interface EmojiObject {
  emoji: Emoji,
  key: string,
  left: number,
  size: number,
}

export const SettingsContext = createContext<IProps>({
  isMobile: false,
  windowIsFocused: true,
  theme: 'light',
  updateTheme: () => null,

  sidebarOpened: false,
  sidebarTab: {
    tab: "none",
  },
  openSidebar: () => { },
  closeSidebar: () => { },

  setInternetSpeed: () => { },
  toggleFullscreen: () => false,
  fullscreenEnabled: false,

  showEmoji: () => false,

  bubbleSize: PARTICIPANT_SIZE,
  audioRangeSize: PARTICIPANT_SIZE,
  setBubbleSize: () => false,
  setAudioRangeSize: () => false,

  floatingCallPosition: { x: 0, y: 0 },
  setFloatingCallPosition: () => { },

  lastWindowResizeTime: new Date()
});

interface Props {
  children?: React.ReactNode
}

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [windowIsFocused, setWindowIsFocused] = useState(true);
  const [fullscreenEnabled, setFullscreenEnabled] = useState(false);
  const [theme, setTheme] = useState<Theme>('light');

  const [internetSpeedMbps, setInternetSpeed] = useState<number | undefined>(undefined)

  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
  const [sidebarTab, setSidebarTab] = useState<SidebarState>({
    tab: "none",
  })

  const [emojiesToShow, setEmojiesToShow] = useState<EmojiObject[]>([]);

  const [bubbleSize, setBubbleSize] = useState<number>(PARTICIPANT_SIZE);
  const [audioRangeSize, setAudioRangeSize] = useState<number>(PARTICIPANT_SIZE);

  const [floatingCallPosition, setFloatingCallPosition] = useState<Position>({ x: window.innerWidth / 2 - 140, y: 20 })

  const [lastWindowResizeTime, setLastWindowResizeTime] = useState(new Date())

  const themeStyles = useMemo(() => {
    const styles = theme === "dark" ? darkStyles : lightStyles

    return styles
  }, [theme])

  const MOBILE_WIDTH: number = 900;

  const updateTheme = useCallback((newTheme: Theme) => {
    setTheme(newTheme);
  }, [setTheme]);

  const closeSidebar = () => {
    setSidebarTab({ tab: "none" })
    setSidebarOpened(false);
  }

  const openSidebar = (tab: SidebarState) => {
    setSidebarOpened(true)
    setSidebarTab(tab)
  }

  //add resize event listener
  //at the beginning
  //when window is resized set lastResizeTime state to call reset panzoom useEffect
  useEffect(() => {
    const onWindowResize = () => {
      if (window._resizeDoneHandle)
        clearTimeout(window._resizeDoneHandle);

      window._resizeDoneHandle = setTimeout(() => {
        setIsMobile(window.innerWidth <= MOBILE_WIDTH)
        setLastWindowResizeTime(new Date())
      }, 300)
    }

    onWindowResize()

    window.addEventListener('resize', onWindowResize)
    window.addEventListener('orientationchange', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize)
      window.removeEventListener('orientationchange', onWindowResize);
    }
  }, [])

  //add focus event listener
  //at the beginning
  useEffect(() => {
    const onWindowFocusChanged = (e: FocusEvent) => {
      setWindowIsFocused(e.type === "focus")
    }

    window.addEventListener('focus', onWindowFocusChanged)
    window.addEventListener('blur', onWindowFocusChanged)
    return () => {
      window.removeEventListener('focus', onWindowFocusChanged)
      window.removeEventListener('blur', onWindowFocusChanged)
    }
  }, [])

  useEffect(() => {
    let css = `
:root {`
    Object.keys(themeStyles).forEach((key: string) => {
      css += `
${key}: ${themeStyles[key]};`
    });
    css += `
}`

    var head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style')

    style.id = "theme_variables"

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    return () => {
      document.querySelector('head style#theme_variables')?.remove()
    };
  }, [themeStyles])

  const toggleFullscreen = useCallback(() => {
    const domDocument: any = document;
    const docElement = document.documentElement as any
    if (!domDocument.fullscreenElement && !domDocument.mozFullScreenElement && !domDocument.webkitFullscreenElement && !domDocument.msFullscreenElement) {
      if (docElement.requestFullscreen) {
        docElement.requestFullscreen();
      } else if (docElement.mozRequestFullScreen) {
        docElement.mozRequestFullScreen();
      } else if (docElement.webkitRequestFullscreen) {
        docElement.webkitRequestFullscreen();
      } else if (docElement.msRequestFullscreen) {
        docElement.msRequestFullscreen();
      }
    } else {
      if (domDocument.exitFullscreen) {
        domDocument.exitFullscreen();
      } else if (domDocument.mozCancelFullScreen) {
        domDocument.mozCancelFullScreen();
      } else if (domDocument.webkitExitFullscreen) {
        domDocument.webkitExitFullscreen();
      } else if (domDocument.msExitFullscreen) {
        domDocument.msExitFullscreen();
      }
    }
  }, [])

  const exitHandler = () => {
    const domDocument: any = document;
    const isDisabled = !domDocument.fullscreenElement &&
      !domDocument.mozFullScreenElement &&
      !domDocument.webkitFullscreenElement &&
      !domDocument.msFullscreenElement;
    setFullscreenEnabled(!isDisabled);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    }
  }, [])

  const showEmoji = useCallback((emoji: Emoji) => {
    setEmojiesToShow([...emojiesToShow, {
      emoji,
      key: uid(),
      left: Math.floor(Math.random() * 80 + 10),
      size: Math.floor(Math.random() * (60 - 30 + 1) + 30),
    }]);
  }, [emojiesToShow]);

  //just to record the last mouse movement
  useEffect(() => {
    const onMouseMove = () => {
      window.lastMouseMovementTime = new Date().getTime()
    }

    window.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    }
  }, [])

  return (
    <SettingsContext.Provider
      value={{
        isMobile,
        windowIsFocused,
        theme,
        updateTheme,

        sidebarOpened,
        sidebarTab,
        openSidebar,
        closeSidebar,

        internetSpeedMbps,
        setInternetSpeed,
        toggleFullscreen,
        fullscreenEnabled,

        showEmoji,

        bubbleSize,
        audioRangeSize,
        setBubbleSize,
        setAudioRangeSize,

        floatingCallPosition,
        setFloatingCallPosition,

        lastWindowResizeTime
      }}
    >
      {children}
      {emojiesToShow.map((item: EmojiObject) => (
        <Emoji
          key={item.key}
          emoji={item.emoji}
          left={item.left}
          size={item.size}
        />
      ))}
    </SettingsContext.Provider>
  );
}
