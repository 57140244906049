import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

const STATUS_PARTS_SEPARATOR = '|'
const _pad = (number: number | string, width: number = 2) => `00000000000${number}`.slice(-width)

const _getStandardDate = (time: Date | string = new Date()) => {
  return moment(new Date(time)).format('YYYY-MM-DD')
}

const _getOnlyTime = (dateTime: Date | string, includeSeconds?: boolean) => moment(new Date(dateTime)).format('HH:mm' + (includeSeconds ? ":ss" : ""))

const _getPrettyTime = (time: Date | string = new Date(), absolute?: boolean) => {
  time = new Date(time)

  if (absolute)
    return `${_getStandardDate(time)} ${_getOnlyTime(time)}`

  const daysDiff = moment().diff(moment(time), 'days');
  return daysDiff === 0 ? _getOnlyTime(time) : moment(time).fromNow();

  // const now = new Date(),
  //   diffSec = (now.getTime() - time.getTime()) / 1000,
  //   timeString = `${_pad(time.getHours())}:${_pad(time.getMinutes())}${noSecond ? '' : `:${_pad(time.getSeconds())}`}`

  // if (diffSec < 86400) {//same day
  //   return timeString
  // }

  // return `${time.getFullYear()}-${_pad(time.getMonth() + 1)}-${_pad(time.getDate())} ${timeString}`
}

const _dist = (a: Position, b: Position) => {
  return Math.sqrt(Math.pow((a.x - b.x), 2) + Math.pow((a.y - b.y), 2))
}

const _trimObject = (inputObject: any) => {
  if (Array.isArray(inputObject)) {
    let trimmedArray = [...inputObject]

    for (let i = 0; i < trimmedArray.length; i++) {
      trimmedArray[i] = _trimObject(trimmedArray[i])
    }

    return trimmedArray
  }

  let trimmedObject: any = { ...inputObject }

  for (var key in trimmedObject) {
    if (trimmedObject.hasOwnProperty(key))
      continue
    switch (typeof trimmedObject[key]) {
      case "string":
        trimmedObject[key] = trimmedObject[key].trim()
        break;
      case "object":
        trimmedObject[key] = _trimObject(trimmedObject[key])
        break
    }
  }

  return trimmedObject
}

const utilities = {

  pad: _pad,

  getPrettyTimeBySeconds: (seconds: number) => {
    return `${_pad(parseInt((seconds / 60).toString()))}:${_pad(parseInt((seconds % 60).toString()))}`
  },

  getPrettyHoursBySeconds: (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    seconds = seconds % 3600
    const minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    // return `${_pad(hours)}:${_pad(minutes)}:${_pad(seconds)}`

    let result = ''
    if (hours > 0)
      result += `${_pad(hours)}h `

    if (minutes > 0)
      result += `${_pad(minutes)}m `

    if (seconds > 0 && hours === 0)
      result += `${_pad(seconds)}s `

    return result.trim()
  },

  getOnlyTime: _getOnlyTime,
  getPrettyTime: _getPrettyTime,
  getStandardDate: _getStandardDate,

  clog: (message: string, ...optionalParams: any[]) => {
    console.log(`${_getPrettyTime(new Date())}: ${message}`, ...optionalParams)
  },

  sleep: (ms: number) => new Promise((r) => setTimeout(r, ms)),

  copyToClipboard: (text: string) => {
    navigator.clipboard.writeText(text);
  },

  urlIsValid: (url: string) => {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    if (res == null)
      return false;
    else
      return true;
  },

  getCircleDistance: (bubble1: BubbleDimension, bubble2: BubbleDimension) => {
    //get radiuses
    const r1 = bubble1.size / 2,
      r2 = bubble2.size / 2,
      //get bubbles centers
      b1X = bubble1.left + r1,
      b1Y = bubble1.top + r1,
      b2X = bubble2.left + r2,
      b2Y = bubble2.top + r2

    //return the distance between the centers minus the radiuses
    return _dist({ x: b1X, y: b1Y }, { x: b2X, y: b2Y }) - (r1 + r2)
  },

  getRectangleDistances: (rect1: Rectangle, rect2: Rectangle) => {

    const a1x = rect1.x,
      a1y = rect1.y,
      a2x = rect1.x + rect1.width,
      a2y = rect1.y + rect1.height,

      b1x = rect2.x,
      b1y = rect2.y,
      b2x = rect2.x + rect2.width,
      b2y = rect2.y + rect2.height

    const left = b2x < a1x, //b is left of a
      right = b1x > a2x, //b is right of a
      top = b2y < a1y, //b is above a
      bottom = b1y > a2y //b is below a

    if (top && left)
      return _dist({ x: a1x, y: a1y }, { x: b2x, y: b2y })

    if (left && bottom)
      return _dist({ x: a1x, y: a2y }, { x: b2x, y: b1y })

    if (bottom && right)
      return _dist({ x: a2x, y: a2y }, { x: b1x, y: b1y })

    if (right && top)
      return _dist({ x: a2x, y: a1y }, { x: b1x, y: b2y })

    if (left)
      return Math.abs(a1x - b2x)

    if (right)
      return Math.abs(b1x - a2x)

    if (bottom)
      return Math.abs(b1y - a2y)

    if (top)
      return Math.abs(a1y - b2y)

    //rectangles intersect
    return 0
  },

  isValidEmail: (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  trimObject: _trimObject,

  parseUserStatus: (status: string) => {
    let result: UserStatus = {
      custom: '',
      dynamic: '' as any,
      static: '' as any
    }

    if (!status)
      return result

    const parts = status.split(STATUS_PARTS_SEPARATOR)

    if (parts.length === 3) {
      result.dynamic = parts[0] as any
      result.static = parts[1] as any
      result.custom = parts[2] as any
      return result
    }

    if (parts.length === 1) {
      result.dynamic = status as any
      return result
    }

    if (parts.length === 2) {
      result.dynamic = parts[0] as any
      result.static = parts[1] as any
      return result
    }

    return result
  },

  userStatusToString: (userStatus: UserStatus) => `${userStatus.dynamic}|${userStatus.static}|${userStatus.custom}`,


  uniqueKey: () => uuidv4(),
  sortByKey: (
    a: any,
    b: any,
    key: string,
    caseSensitive: boolean = true,
  ) => {
    const aKey = !caseSensitive && typeof a[key] === 'string'
      ? a[key]?.toLowerCase()
      : a[key];
    const bKey = !caseSensitive && typeof b[key] === 'string'
      ? b[key]?.toLowerCase()
      : b[key];
    if (aKey > bKey) return 1;
    if (aKey < bKey) return -1;

    return 0;
  },

  getWordWrappedText: (text: string, maxLength: number) => {
    if (text.length <= maxLength)
      return text

    return `${text.substring(0, maxLength)}...`
  },

  getSocketUserFromMember: (member: Participant, roomId: number) => {
    return {
      name: member.slimUser?.firstName || '',
      status: utilities.userStatusToString({
        custom: '',
        dynamic: 'Offline',
        static: ''
      }),
      userId: member.userId,
      image: member.slimUser?.profilePicture96x96URL,
      roomId: roomId,
      isSpaceAdmin: member.participantType === "SPACE_ADMIN"
    } as SocketUser
  },

  getMousePosition: (e: React.MouseEvent) => ({
    x: e.clientX - 76,
    y: e.clientY
  })
}


export default utilities