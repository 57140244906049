import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import classNames from 'classnames'

import Backdrop from "../Backdrop/Backdrop"
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import IconButton from '../IconButton/IconButton'

import { useOnClickOutside } from '../../../hooks/useClickOutside'

import styles from './Alert.module.scss'

interface AlertProps extends BubbleAlert {
  onClose?: () => void
  onClick?: (event: React.MouseEvent) => void
  inline?: boolean
  onButtonClick?: (e: React.MouseEvent) => void
}

const Alert: React.FC<AlertProps> = (props) => {
  const { type = "info", title, message, onClose, onPrimaryButtonClick, primaryButtonText,
    secondaryButtonText, closeTimeout, hideBackdrop, hideCloseIcon, noCloseOrCancel, onSecondaryButtonClick,
    customIconName, onTertiaryButtonClick, tertiaryButtonText, inline, onClick, onButtonClick } = props

  const ref: any = useRef();
  useOnClickOutside(ref, () => !noCloseOrCancel && onClose && onClose());

  const mounted = useRef(false);
  useEffect(() => { mounted.current = true; return () => { mounted.current = false; }; }, []);

  const alertTitle = useMemo(() => {
    if (title)
      return title

    switch (type) {
      case "error": return "Error"
      case "info": return "Information"
      case "warning": return "Warning"
      case "success": return "Successful"
    }
  }, [title, type])

  const showFooter = useMemo(() => {
    return !!onPrimaryButtonClick || !!onTertiaryButtonClick || !!onSecondaryButtonClick
  }, [onPrimaryButtonClick, onTertiaryButtonClick, onSecondaryButtonClick])

  useEffect(() => {
    let timeoutHandle: any = undefined
    if (closeTimeout && onClose && mounted.current) {
      timeoutHandle = setTimeout(() => {
        onClose();
      }, closeTimeout);
    }

    return () => { clearTimeout(timeoutHandle) }
  }, [closeTimeout, onClose]);

  const closeAlert = useCallback(() => {
    if (noCloseOrCancel)
      return

    onClose && onClose()

  }, [onClose, noCloseOrCancel])

  const hasOnlyTertiaryButton = useMemo(() => !onPrimaryButtonClick && !onSecondaryButtonClick && !!onTertiaryButtonClick, [onPrimaryButtonClick, onSecondaryButtonClick, onTertiaryButtonClick])

  const getContent = () => (
    <div
      className={classNames(styles.alert, { [styles.inline]: !!inline })}
      ref={ref}
      onClick={onClick}
    >
      <header>
        <Icon
          size={20}
          color={type === "error" ? "danger" : type}
          name={customIconName || `alert-${type}`}
        />

        <Typography
          component='span'
          weight={600}
          color={type === "error" ? "danger" : type}
          className={styles.title}
        >
          {alertTitle}
        </Typography>
        {
          !noCloseOrCancel && onClose && !hideCloseIcon &&
          <IconButton iconSize={20} iconName='close' handleClick={e => {
            onClose()
            e.stopPropagation()
            e.preventDefault()
            return false
          }} />
        }
      </header>
      <div className={styles.content}>
        <Typography component='div' variant='body-2'>{message}</Typography>
      </div>
      {showFooter && (
        <div className={styles.actions}>
          <div className={classNames(styles.dismiss, { [styles.only]: hasOnlyTertiaryButton })}>
            {onTertiaryButtonClick &&
              <Button
                className={styles.button}
                size='small'
                variant='destructive'
                onClick={e => {
                  onTertiaryButtonClick()
                  closeAlert()
                  onButtonClick && onButtonClick(e)
                  e.stopPropagation()
                  e.preventDefault()
                  return false
                }}
              >
                {tertiaryButtonText || 'Dismiss'}
              </Button>
            }
          </div>
          {!hasOnlyTertiaryButton &&
            <div className={styles.main}>
              {!noCloseOrCancel && onSecondaryButtonClick &&
                <Button
                  className={styles.button}
                  size='small'
                  variant='secondary'
                  onClick={e => {
                    onSecondaryButtonClick()
                    closeAlert()
                    onButtonClick && onButtonClick(e)
                    e.stopPropagation()
                    e.preventDefault()
                    return false
                  }}
                >
                  {secondaryButtonText || 'Cancel'}
                </Button>
              }
              {onPrimaryButtonClick &&
                <Button
                  className={styles.button}
                  size='small'
                  variant='primary-a'
                  onClick={e => {
                    onPrimaryButtonClick && onPrimaryButtonClick()
                    closeAlert()
                    onButtonClick && onButtonClick(e)
                    e.stopPropagation()
                    e.preventDefault()
                    return false
                  }}
                >
                  {primaryButtonText || 'Ok'}
                </Button>
              }
            </div>
          }
        </div>
      )}
    </div>
  )

  if (inline)
    return getContent()

  return <Backdrop
    className={classNames(styles.backdrop, {
      [styles.hidden]: hideBackdrop,
    })}
  >
    <div className={styles.alertWrapper}>
      {getContent()}
    </div>
  </Backdrop>
}

export default Alert