import { CSSProperties, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';

import styles from './Tooltip.module.scss';
import { SPACE_ROOT_ELEMENT_ID } from '../../../constants/environment';

export interface TooltipOptions {
  placement?: 'top' | 'bottom',
  yOffset?: number,
  xOffset?: number,
  fontSize?: number,
  lineHeight?: number,
  inverted?: boolean,
}

interface IProps {
  children: any,
  className?: string,
  options?: TooltipOptions,
  position?: Position,
}

export default function Tooltip({
  children,
  className,
  options,
  position,
}: IProps) {
  const [tooltipDiv, setRef] = useState<HTMLDivElement | null>(null)
  const tooltipOptions = {
    placement: options?.placement || 'bottom',
    yOffset: options?.yOffset === undefined ? 0 : options?.yOffset,
    xOffset: options?.xOffset === undefined ? 0 : options?.xOffset,
    fontSize: options?.fontSize || 16,
    lineHeight: options?.lineHeight || 24,
    inverted: options?.inverted || false,
  };

  const mySize = useMemo<ImageSize>(() => {
    if (!tooltipDiv)
      return {
        width: 0,
        height: 0
      }

    return {
      width: tooltipDiv.clientWidth,
      height: tooltipDiv.clientHeight,
    }
  }, [tooltipDiv])

  let xPos = position?.x || 0
  let yPos = position?.y || 0

  const verticalPlacement = useMemo(() => {
    if (!mySize.height)
      return tooltipOptions.placement

    if (tooltipOptions.placement === 'bottom' && (yPos + mySize.height) > window.innerHeight)
      return "top"

    if (tooltipOptions.placement === 'top' && yPos < 0)
      return "bottom"

    return tooltipOptions.placement
  }, [tooltipOptions.placement, yPos, mySize.height])

  const horizontalPlacement = (xPos + mySize.width) > window.innerWidth ? "left" : "right"

  if (verticalPlacement === "top")
    yPos = window.innerHeight - yPos

  if (horizontalPlacement === "left")
    xPos = window.innerWidth - xPos

  if (horizontalPlacement === "right")
    xPos = xPos - 76 //sidebar width

  const verticalProp = verticalPlacement === "top" ? "bottom" : "top"
  const horizontalProp = horizontalPlacement === "left" ? "right" : "left"

  let tooltipStyles: CSSProperties = {
    [verticalProp]: yPos + tooltipOptions.yOffset * (verticalPlacement === "top" ? -1 : 1),
    [horizontalProp]: xPos + tooltipOptions.xOffset,
    fontSize: `${tooltipOptions.fontSize}px`,
    lineHeight: `${tooltipOptions.lineHeight}px`,
  };

  return (
    <Portal node={document.getElementById(SPACE_ROOT_ELEMENT_ID)}>
      <div
        ref={setRef}
        className={classNames(styles.tooltip, className, {
          [styles.inverted]: tooltipOptions.inverted,
        })}
        style={tooltipStyles}
      >
        {children}
      </div>
    </Portal>
  )
}
