import { useDeepCompareEffect } from 'use-deep-compare';
import socketUtil from '../utils/socket'


// const listeners: { name: string, callback: (data: any) => void }[] = []

export default function useOnSocketMessage(listenerName: string, messageTypes: string[], callback: (data: any) => void) {
  const socket = socketUtil.getSocket()

  useDeepCompareEffect(() => {
    // if (!socket?.connected)
    //   return

    //console.log("C1009 Add message event " + listenerName)

    // const index = listeners.findIndex(l => l.name === listenerName)
    // if (index >= 0) {
    //   socket?.off('push', listeners[index].callback)
    //   listeners[index].callback = callback
    // }
    // else {
    //   listeners.push({
    //     name: listenerName,
    //     callback
    //   })
    // }

    const onPush = (message: any) => {
      if (messageTypes.length > 0 && !messageTypes.includes(message.notificationEventType))
        return

      console.log(`C1009 ${listenerName} received ${message.notificationEventType}`, message);

      callback(message)
    }

    socket?.on('push', onPush)

    return () => {
      //console.log("C1009 =========== remove message event " + listenerName)
      socket?.off('push', onPush)
      // const index = listeners.findIndex(l => l.name === listenerName)
      // if (index >= 0) {
      //   listeners.splice(index, 1)
      // }
    }
  }, [listenerName, messageTypes, callback])
}