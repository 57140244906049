import { useEffect, useState } from 'react';
import { io } from 'socket.io-client'
import { socketIoServerUrl, USE_LOCAL_SOCKET } from '../constants/environment';
import api from '../utils/api';
import { localStorageProvider } from '../utils/localStorageProvider';
import socketUtil from '../utils/socket'
import Bowser from 'bowser';

export const getIPLocationInfo = async () => {

  if (window.location.href.toLowerCase().indexOf('localhost:'))
    return {
      country_name: 'localhost',
      time_zone: {
        name: 'localhost/timezone'
      }
    } as IPLocationInfo

  const lastStoredIPInfo = localStorageProvider.lastIPInfo.get()
  const ip = await api.user.getIP()

  if (!ip)
    return lastStoredIPInfo

  if (lastStoredIPInfo?.ip === ip)
    return lastStoredIPInfo

  const ipInfo = await api.user.getIPLocationInfo()

  if (!ipInfo)
    return lastStoredIPInfo

  localStorageProvider.lastIPInfo.set(ipInfo)

  return ipInfo
}

export default function useSocketIo(token: string) {
  const [status, setStatus] = useState<SocketStatus>("uninitiated")

  //connect
  useEffect(() => {
    if (!token)
      return

    const createSocket = async () => {
      const oldSocket = socketUtil.getSocket()

      if (oldSocket && oldSocket.connected)
        return

      const ipLocationInfo = {} as any //await getIPLocationInfo()
      const clientInfo = Bowser.parse(navigator.userAgent)

      const skt = io(socketIoServerUrl, {
        transports: [USE_LOCAL_SOCKET ? "polling" : "websocket"],
        upgrade: USE_LOCAL_SOCKET ? undefined : false,
        closeOnBeforeunload: false,
        timeout: 2000,
        auth: {
          token,
          timezone: ipLocationInfo?.time_zone?.name || '',
          country: ipLocationInfo?.country_name || '',
          clientInfo
        }
      })

      skt.on("connect_error", e => {
        setStatus('error')
        console.log("C1009 connect error", e.message)
      })

      skt.on("connect", () => {
        setStatus('connected')
        console.log("C1009 got connected")
      })

      skt.on("disconnect", () => {
        setStatus('disconnected')
        console.log("C1009 got disconnected")
      })

      // skt.on("push", (message) => {
      //   console.log("C1009 push", message)
      //   setLastMessage(message)
      // })

      socketUtil.setSocket(skt)
    }

    createSocket()

    return () => {
      const oldSocket = socketUtil.getSocket()

      if (oldSocket && oldSocket.connected) {
        console.log("C1009 closing the old socket")
        oldSocket.close()
      }
    }
  }, [token])

  return {
    socketStatus: status
  }
}
