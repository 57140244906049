import React, { useState, useEffect } from 'react';
import { Portal } from 'react-portal';
import { SPACE_ROOT_ELEMENT_ID } from '../../../constants/environment';

import styles from './Emoji.module.scss';

interface IProps {
  emoji: Emoji,
  left: number,
  size: number,
}

export default function Emoji({ emoji, left, size }: IProps) {
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    let timeoutHandle = setTimeout(() => setIsExpired(true), 10000);
    return () => { clearTimeout(timeoutHandle) }
  }, []);

  if (isExpired)
    return null

  return (
    <Portal node={document.getElementById(SPACE_ROOT_ELEMENT_ID)}>
      <div
        className={styles.wrapper}
        style={{ left: `${left}%` }}
      >
        <img
          alt={emoji}
          height={size}
          src={`/assets/emoji/${emoji}.png`}
        />
      </div>
    </Portal>
  )
}
