import classNames from 'classnames';
import * as React from 'react'
import { Portal } from 'react-portal';
import styles from './Backdrop.module.scss'

interface BackdropProps {
  children?: React.ReactNode
  className: string
}

const Backdrop: React.FC<BackdropProps> = ({ className, children }) => {
  return (
    <Portal node={window.document.body}>
      <div className={classNames(styles.backdrop, className)}>
        {children}
      </div>
    </Portal>
  )
}

export default Backdrop