import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { SettingsProvider } from '../contexts/SettingsProvider';
import Loader from './common/Loader/Loader';
import { AppProvider } from '../contexts/AppProvider';
import { usePrevious } from '../hooks/usePrevious';

const load = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
};

const SpacePage = load(lazy(() => import('./SpacePage')));
const SpaceNotFoundPage = load(lazy(() => import('./SpaceNotFound/SpaceNotFound')));
const LoginPage = load(lazy(() => import('./LoginPage/LoginPage')));
const SpaceCreate = load(lazy(() => import('./SpaceCreate/SpaceCreate')));
const StyleGuide = load(lazy(() => import('./Styleguide')));

const AppContent = () => {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if (location.pathname.toLowerCase() === "/login" && !!prevLocation?.pathname) {
      window.location.reload()
    }

  }, [location, prevLocation])

  return <Routes>
    <Route path="/create-space" element={<SpaceCreate />} />
    <Route path='/styleGuide' element={<StyleGuide />} />
    <Route path='/login' element={<LoginPage />} />
    <Route path='/onboard-user' element={<LoginPage page="onboarding" />} />
    <Route path='/reset-user-password' element={<LoginPage page="forgot-password" />} />
    <Route path='/join-member' element={<LoginPage page="invited-member" />} />
    <Route path='/magic-link' element={<LoginPage page="magic-link" />} />
    <Route path="/" element={<SpacePage />} />
    <Route path="/space-not-found" element={<SpaceNotFoundPage />} />
  </Routes>
}

export default function App() {

  return (
    <BrowserRouter>
      <SettingsProvider>
        <AppProvider>
          <AppContent />
        </AppProvider>
      </SettingsProvider>
    </BrowserRouter>
  )
}
