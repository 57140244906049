import { Socket } from "socket.io-client"
import utilities from "./utilities"

let myCurrentRooms: string[] = []
const setSocket = (skt: Socket) => (window as any)._skt = skt
const getSocket = () => (window as any)._skt as Socket | null

const socket = (() => {

  const _joinLeave = (isJoin: boolean, roomNames: string[]) => {
    // console.log(`C1009  ${isJoin ? "joining" : "leaving"} `, roomNames)
    const socket = getSocket()

    if (roomNames.length === 0)
      return

    if (isJoin) {
      //get new room names
      roomNames = roomNames.filter(r => !myCurrentRooms.includes(r))

      //add the new rooms to my list
      myCurrentRooms.push(...roomNames)
    }
    else {
      //get the rooms that I'm joined already
      roomNames = roomNames.filter(r => myCurrentRooms.includes(r))

      //remove the room names from my list
      myCurrentRooms = myCurrentRooms.filter(r => !roomNames.includes(r))
    }

    if (roomNames.length === 0)
      return

    if (!socket || !socket.connected)
      return

    socket.emit(isJoin ? "join" : "leave", roomNames)
    console.log(`C1009  ${isJoin ? "joined" : "left"} `, roomNames.length === 1 ? roomNames[0] : roomNames)
  }

  const _simpleEmit = (emitName: string, ...args: any[]) => {
    const socket = getSocket()

    if (!socket || !socket.connected)
      return

    socket.emit(emitName, ...args)
  }

  const disconnect = () => {
    const socket = getSocket()

    if (!socket || !socket.connected)
      return

    socket.disconnect()
  }

  let _socketUserMetadata: SocketUserMetadata = {}
  let _setMetadataTimeoutHandle: NodeJS.Timeout = null!
  const _setMetadata = (metadata: SocketUserMetadata) => {

    _socketUserMetadata = {
      ..._socketUserMetadata,
      ...metadata
    }

    if (_setMetadataTimeoutHandle)
      clearTimeout(_setMetadataTimeoutHandle)

    _setMetadataTimeoutHandle = setTimeout(() => {

      _simpleEmit("setMetadata", _socketUserMetadata)
    }, 50);
  }

  return {
    disconnect,
    join: (roomNames: string[]) => _joinLeave(true, roomNames),
    leave: (roomNames: string[]) => _joinLeave(false, roomNames),
    getConnectedUsers: (spaceId: number) => _simpleEmit("getConnectedUsers", spaceId),

    getSocket,
    setSocket,
    getSocketId: () => getSocket()?.id || '',

    setStatus: (status: UserStatus) => _setMetadata({ status: utilities.userStatusToString(status) }),
    setIsSpaceAdmin: (isSpaceAdmin: boolean) => _setMetadata({ isSpaceAdmin }),
    setRoomPosition: (roomId: number, roomPosition: SocketUserRoomPosition) => _setMetadata({ roomId, roomPosition }),
    setRoomId: (roomId: number) => _setMetadata({ roomId }),

    setImage: (image: string) => _simpleEmit("setImage", image),
    setName: (name: string) => _simpleEmit("setName", name),
    privateMessage: (spaceId: number, userId: number, message: SocketPrivateMessage) => _simpleEmit("privateMessage", spaceId, userId, message)
  }
})()

export default socket