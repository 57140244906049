import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Tooltip, { TooltipOptions } from '../Tooltip/Tooltip';

import styles from './IconButton.module.scss';

interface IProps {
  className?: string,
  handleClick?: (event: React.MouseEvent) => void
  iconColor?: IconColor
  iconName: IconName
  imageIcon?: string
  iconSize?: number
  title?: string,
  tooltipClassName?: string
  withTooltip?: boolean
  tooltipOptions?: TooltipOptions
  id?: string
  disabled?: boolean
}

export default function IconButton({
  className,
  handleClick,
  iconColor,
  iconName,
  imageIcon,
  iconSize,
  title,
  tooltipClassName,
  tooltipOptions,
  withTooltip,
  disabled,
  ...rest
}: IProps) {
  const ref = useRef<HTMLDivElement>();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles.button, className)}
      onClick={e => {
        if (disabled)
          return;
        setTooltipOpen(false)
        handleClick && handleClick(e)
      }}
      onMouseEnter={() => withTooltip && setTooltipOpen(true)}
      onMouseLeave={() => withTooltip && setTooltipOpen(false)}
      ref={ref as any}
      {...rest}
    >
      {imageIcon ?
        <div
          className={styles.imageIcon}
          style={{
            width: iconSize || 24,
            height: iconSize || 24,
            backgroundImage: `url(${imageIcon})`
          }}
        ></div>
        :
        <Icon
          color={iconColor}
          name={iconName}
          size={iconSize}
        />
      }
      {tooltipOpen && (
        <Tooltip
          className={tooltipClassName}
          position={{
            x: ref?.current ? ref.current.getBoundingClientRect().x : 0,
            y: ref?.current ? ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height : 0
          }}
          options={tooltipOptions}
        >
          {title}
        </Tooltip>
      )}
    </div>
  )
}
